import { FC } from 'react';
import { Box } from 'components/common/Box';
import { DashboardCard } from '../DashboardCard';
import { AppPaths } from 'urls/frontend';

export const Dashboard: FC = () => {
  return (
    <Box pt={5} display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={3}>
        <DashboardCard
          name="Top 15 Kompass - Fonds"
          subtitle="Sharpe Ratio"
          link={`${AppPaths.customerOverview}?tab=Kompass`}
        />
        <DashboardCard
          name="Flop 15 Kompass - Fonds"
          subtitle="Sharpe Ratio"
          link={`${AppPaths.customerOverview}?tab=Kompass`}
          isWarning
        />
      </Box>
    </Box>
  );
};
