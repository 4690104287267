import { FC } from 'react';
import { LetterBadgeProps } from './LetterBadge.d';
import { StyledContainer } from './LetterBadge.styled';
import { Box } from 'components/common/Box';

export const LetterBadge: FC<LetterBadgeProps> = ({
  value,
  size = 'large',
  color,
  backgroundColor,
  borderColor,
}) => {
  return (
    <Box display="flex" justifyContent="center">
      {value ? (
        <StyledContainer
          value={value}
          color={color}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          size={size}
        >
          {value}
        </StyledContainer>
      ) : (
        '-'
      )}
    </Box>
  );
};
