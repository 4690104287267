import { FC } from 'react';
import { RequestedByColumnProps } from './RequestedByColumn.d';
import { Box } from 'components/common/Box';
import { ReactComponent as UserIcon } from 'assets/Icons/profile-circle-grey.svg';
import { Typography } from 'components/common/Typography';

export const RequestedByColumn: FC<RequestedByColumnProps> = ({ name }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <UserIcon style={{ width: 20 }} />
      <Typography variant="body" style={{ lineHeight: '17px' }}>
        {name}
      </Typography>
    </Box>
  );
};
