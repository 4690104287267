import { FC } from 'react';
import { CommentItemProps } from '../CommentsModal.d';
import { Box } from 'components/common/Box';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { COLORS } from 'theme/colors';
import { formatDistanceToNow, parse } from 'date-fns';
import {
  StyledComment,
  StyledCommentContainer,
  StyledDate,
  StyledName,
} from './CommentItem.styled';
import { de } from 'date-fns/locale';

export const CommentItem: FC<CommentItemProps> = ({
  isLast = false,
  name,
  comment,
  date,
}) => {
  const commentDate = parse(date, 'yyyy-MM-dd HH:mm:ss X', new Date());
  const formattedDate = formatDistanceToNow(commentDate, {
    addSuffix: true,
    locale: de,
  });

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <LetterBadge
          value={name[0]}
          size="medium"
          color={COLORS.background.secondary}
          backgroundColor={COLORS.info.selectedBorder}
          borderColor={COLORS.info.selectedBorder}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          <StyledName variant="h5" weight="bold">
            {name}
          </StyledName>
          <StyledDate variant="body">{formattedDate}</StyledDate>
        </Box>
      </Box>
      <StyledCommentContainer isLast={isLast}>
        <StyledComment>{comment}</StyledComment>
      </StyledCommentContainer>
    </Box>
  );
};
