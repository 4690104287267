import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';
import { SettlementPeriodsProps } from './PurchasePriceIndicator.d';

export const gradeColors = [
  {
    grade: 'A',
    backgroundColor: COLORS.success.bulletPoint,
    borderColor: COLORS.success.bulletPoint,
    textColor: COLORS.background.secondary,
  },
  {
    grade: 'B',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: 'C',
    backgroundColor: COLORS.background.module,
    borderColor: COLORS.stroke.primary,
    textColor: COLORS.typography.description,
  },
  {
    grade: 'D',
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
  {
    grade: 'E',
    backgroundColor: COLORS.error.background,
    borderColor: COLORS.error.background,
    textColor: COLORS.background.secondary,
  },
];

export const settlementPeriods: SettlementPeriodsProps[] = [
  {
    period: [0, 0],
    backgroundColor: COLORS.background.module,
    borderColor: COLORS.stroke.primary,
    textColor: COLORS.typography.description,
  },
  {
    period: [1, 2],
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    period: [3, 4],
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
  {
    period: [5, 10],
    backgroundColor: COLORS.error.background,
    borderColor: COLORS.error.background,
    textColor: COLORS.background.secondary,
  },
];
