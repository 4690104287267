import { FC, useState } from 'react';
import { HeaderProps } from './Header.d';
import { NavigationMenu } from '../NavigationMenu';
import { ReactComponent as LightLogo } from 'assets/white-logo.svg';
import { ReactComponent as DarkLogo } from 'assets/logo.svg';
import {
  StyledContainer,
  StyledMyFundListIcon,
  StyledComparisonListIcon,
  StyledLink,
  StyledComparisonListContainer,
  StyledMyFundListContainer,
  StyledExternalModuleContainer,
  StyledMLPDepotsIcon,
} from './Header.styled';
import { Box } from '../Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserMenu } from 'components/User/UserMenu';
import { useProfile } from 'context/Profile';
import { AppPaths } from 'urls/frontend';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';
import { InviteButton } from '../Buttons/InviteButton';
import { InviteUserModal } from '../Modals/InviteUserModal';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const Header: FC<HeaderProps> = ({ variant }) => {
  const { pathname } = useLocation();
  const profile = useProfile();
  const { customNavigate } = useNavigation();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const isHomePage = pathname === AppPaths.main;
  const isPrimaryHeader = variant === 'primary';

  const showInviteUserModal = () => {
    setIsInviteUserModalOpen(true);
  };

  return (
    <StyledContainer variant={variant}>
      {profile?.profileInfo?.can_invite_users && (
        <InviteUserModal
          isShown={isInviteUserModalOpen}
          toggle={() => setIsInviteUserModalOpen(false)}
        />
      )}
      <Box display="flex" alignItems="center" gap={3}>
        <NavigationMenu variant={variant} />
        <StyledLink
          to={AppPaths.main}
          isClickable={!isHomePage}
          onClick={() => customNavigate(AppPaths.main)}
        >
          {isPrimaryHeader ? <DarkLogo /> : <LightLogo />}
        </StyledLink>
      </Box>
      <Box display="flex" alignItems="center" gap={4}>
        <StyledExternalModuleContainer
          variant={variant}
          onClick={() => customNavigate(AppPaths.customerOverview)}
          display="flex"
          alignItems="center"
          gap={1}
          isCurrentPage={pathname === AppPaths.customerOverview}
        >
          <StyledMLPDepotsIcon
            variant={variant}
            isCurrentPage={pathname === AppPaths.customerOverview}
          />
          <Typography
            variant="body"
            weight={
              pathname === AppPaths.customerOverview ? 'semibold' : 'regular'
            }
            color={
              isPrimaryHeader
                ? COLORS.typography.description
                : COLORS.background.secondary
            }
          >
            Plansecur
          </Typography>
        </StyledExternalModuleContainer>
        {profile?.profileInfo?.mlp_depots_access && (
          <StyledExternalModuleContainer
            variant={variant}
            onClick={() => customNavigate(AppPaths.mlpDepots)}
            display="flex"
            alignItems="center"
            gap={1}
            isCurrentPage={pathname === AppPaths.mlpDepots}
          >
            <StyledMLPDepotsIcon
              variant={variant}
              isCurrentPage={pathname === AppPaths.mlpDepots}
            />
            <Typography
              variant="body"
              weight={pathname === AppPaths.mlpDepots ? 'semibold' : 'regular'}
              color={
                isPrimaryHeader
                  ? COLORS.typography.description
                  : COLORS.background.secondary
              }
            >
              MLP Depots
            </Typography>
          </StyledExternalModuleContainer>
        )}
        <StyledComparisonListContainer
          variant={variant}
          onClick={() => customNavigate(AppPaths.comparisonList)}
          display="flex"
          alignItems="center"
          gap={1}
          isCurrentPage={pathname === AppPaths.comparisonList}
        >
          <StyledComparisonListIcon
            variant={variant}
            isCurrentPage={pathname === AppPaths.comparisonList}
          />
          <Typography
            variant="body"
            weight={
              pathname === AppPaths.comparisonList ? 'semibold' : 'regular'
            }
            color={
              isPrimaryHeader
                ? COLORS.typography.description
                : COLORS.background.secondary
            }
          >
            Mein Fondsvergleich
          </Typography>
        </StyledComparisonListContainer>
        <StyledMyFundListContainer
          variant={variant}
          onClick={() => customNavigate(AppPaths.myWatchlist)}
          display="flex"
          alignItems="center"
          gap={1}
          isCurrentPage={pathname === AppPaths.myWatchlist}
        >
          <StyledMyFundListIcon
            variant={variant}
            isCurrentPage={pathname === AppPaths.myWatchlist}
          />
          <Typography
            variant="body"
            weight={pathname === AppPaths.myWatchlist ? 'semibold' : 'regular'}
            color={
              isPrimaryHeader
                ? COLORS.typography.description
                : COLORS.background.secondary
            }
          >
            Meine Fondsliste
          </Typography>
        </StyledMyFundListContainer>
        {profile?.profileInfo && (
          <UserMenu email={profile?.profileInfo?.email} variant={variant} />
        )}
        {profile?.profileInfo?.can_invite_users && (
          <InviteButton variant={variant} onClick={showInviteUserModal} />
        )}
      </Box>
    </StyledContainer>
  );
};
