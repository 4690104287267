import { FC } from 'react';
import { SelectedRowProps } from './SelectedRow.d';
import {
  StyledContainer,
  StyledButton,
  StyledIconContainer,
  StyledButtonsContainer,
} from './SelectedRow.styled';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { ReactComponent as ExportIcon } from 'assets/Icons/export.svg';
import { ReactComponent as AddToWatchListIcon } from 'assets/Icons/add-fund.svg';
import { ReactComponent as FolderIcon } from 'assets/Icons/folder-add.svg';
import { COLORS } from 'theme/colors';

export const SelectedRow: FC<SelectedRowProps> = ({
  variant,
  selectedItemsNumber,
  isAddMultipleFundsButtonEnabled,
  handleAddToWatchlist,
  handleExport,
  handleAddToPortfolio,
  handleAddToCompassDesign,
  handleCancelSelectingRows,
}) => {
  const isWatchlistVariant = variant === 'watchlist';

  return (
    <StyledContainer
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pl={5}
      pr={2}
    >
      <Typography
        variant="body"
        color={COLORS.background.secondary}
        weight="medium"
      >
        {selectedItemsNumber} Element{selectedItemsNumber !== 1 && 'e'}{' '}
        ausgewählt
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <StyledButtonsContainer
          display="flex"
          alignItems="center"
          gap={4}
          pr={2}
        >
          {handleExport && (
            <StyledButton onClick={handleExport}>
              <StyledIconContainer>
                <ExportIcon />
              </StyledIconContainer>
              Export
            </StyledButton>
          )}
          {handleAddToWatchlist && !isWatchlistVariant && (
            <StyledButton
              onClick={handleAddToWatchlist}
              disabled={!isAddMultipleFundsButtonEnabled}
            >
              <StyledIconContainer>
                <AddToWatchListIcon fill={COLORS.background.secondary} />
              </StyledIconContainer>
              Zu Meine Fondsliste hinzufügen
            </StyledButton>
          )}
          {handleAddToPortfolio && (
            <StyledButton
              onClick={handleAddToPortfolio}
              disabled={!isAddMultipleFundsButtonEnabled}
            >
              <StyledIconContainer>
                <AddToWatchListIcon fill={COLORS.background.secondary} />
              </StyledIconContainer>
              Zur Portfolio hinzufügen
            </StyledButton>
          )}
          {handleAddToCompassDesign && (
            <StyledButton onClick={handleAddToCompassDesign}>
              <StyledIconContainer>
                <FolderIcon fill={COLORS.background.secondary} />
              </StyledIconContainer>
              Zur Entwurf Kompass hinzufügen
            </StyledButton>
          )}
        </StyledButtonsContainer>
        <StyledButton onClick={handleCancelSelectingRows}>
          Abbrechen
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};
