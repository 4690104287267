import { ReactComponent as LetterPIcon } from 'assets/Icons/Plansecur/letter-p.svg';
import { ReactComponent as LetterLIcon } from 'assets/Icons/Plansecur/letter-l.svg';
import { ReactComponent as LetterAIcon } from 'assets/Icons/Plansecur/letter-a.svg';
import { ReactComponent as LetterNIcon } from 'assets/Icons/Plansecur/letter-n.svg';
import { ReactComponent as LetterSIcon } from 'assets/Icons/Plansecur/letter-s.svg';
import { ReactComponent as LetterEIcon } from 'assets/Icons/Plansecur/letter-e.svg';
import { ReactComponent as LetterCIcon } from 'assets/Icons/Plansecur/letter-c.svg';
import { ReactComponent as LetterUIcon } from 'assets/Icons/Plansecur/letter-u.svg';
import { ReactComponent as LetterRIcon } from 'assets/Icons/Plansecur/letter-r.svg';

export const customerOverviewLogoIcons = [
  <LetterPIcon />,
  <LetterLIcon />,
  <LetterAIcon />,
  <LetterNIcon />,
  <LetterSIcon />,
  <LetterEIcon />,
  <LetterCIcon />,
  <LetterUIcon />,
  <LetterRIcon />,
];
