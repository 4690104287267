import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { GradeColorProps, StyledContainerProps } from './LetterBadge.d';
import { gradeColors } from './config';

const getColorsByValue = (value: string): GradeColorProps | undefined => {
  return gradeColors.find(({ grade }) => grade === value);
};

const getSize = (size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '30px';
    case 'medium':
      return '35px';
    case 'large':
      return '40px';
    default:
      return '40px';
  }
};

const getFontSize = (size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '20px';
    case 'medium':
      return '24px';
    case 'large':
      return '13px';
    default:
      return '13px';
  }
};

export const StyledContainer = styled(Box)<StyledContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ value, backgroundColor }) =>
    backgroundColor || getColorsByValue(value)?.backgroundColor};
  color: ${({ value, color }) => color || getColorsByValue(value)?.textColor};
  border: 1px solid
    ${({ value, borderColor }) =>
      borderColor || getColorsByValue(value)?.borderColor};
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  font-family: 'Inter', sans-serif;
  font-size: ${({ size }) => getFontSize(size)};
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
