import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  height: 100%;
`;

export const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  color: ${COLORS.background.secondary};
  transition-duration: 0.2s;

  &:enabled {
    cursor: pointer;
    &:hover {
      svg {
        fill: ${COLORS.accent.hover};
      }
    }
  }
`;

export const StyledMoreActionsButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${COLORS.background.hover};
    }
  }

  &:active {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }
`;

export const StyledSuggestActionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & button {
    padding: 7px 6px;
    font-size: 12px;
  }
`;

export const StyledTooltipHeader = styled(Typography)`
  font-size: 16px;
  font-family: 'Random Grotesque Standard', sans-serif;
  font-weight: 700;
  line-height: 23px;
  color: ${COLORS.background.neutral};
`;

export const StyledTooltipContent = styled(Typography)`
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 17px;
  color: ${COLORS.background.neutral};
`;
