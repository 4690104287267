import { FC, useEffect, useState } from 'react';
import {
  SearchContainer,
  StyledContainer,
} from './ComparisonListContent.styled';
import { Box } from 'components/common/Box';
import { Switch } from 'components/common/Switch';
import { ComparisonListChart } from '../ComparisonListChart';
import { rows } from './config';
import { VerticalCollapseTable } from 'components/common/VerticalCollapseTable';
import {
  CreatedColumnComparisonList,
  CreatedRowComparisonList,
} from 'components/common/VerticalCollapseTable/VerticalCollapseTable.d';
import { ComparisonListItemProps } from 'types/ComparisonList.d';
import {
  fetchSmartBenchmarkingComparisonList,
  fetchUpdateComparisonListStatusSmartBenchmarking,
} from 'services/ComparisonList';
import { Typography } from 'components/common/Typography';
import { format } from 'date-fns';
import { formatCurrency, formatNumberWithComma } from 'utils/common';
import {
  fetchUpdateFundListStatusSmartBenchmarking,
  sendRequestReportSmartBenchmarking,
} from 'services/Modules';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { RemoveFromComparisonListModal } from 'components/common/Modals/RemoveFromComparisonListModal';
import { backgroundColors } from '../ComparisonListChart/config';
import { ComparisonListSearch } from './ComparisonListSearch/ComparisonListSearch';
import { useProfile } from 'context/Profile';

export const ComparisonListContent: FC = () => {
  const profile = useProfile();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<CreatedColumnComparisonList[]>([]);
  const [isDifferencesHighlighted, setIsDifferencesHighlighted] =
    useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
  });
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [
    isConfirmRemoveFromComparisonOpen,
    setIsConfirmRemoveFromComparisonOpen,
  ] = useState(false);
  const [pinnedColumn, setPinnedColumn] = useState<CreatedColumnComparisonList>(
    columns[0],
  );

  const getFilteredColumns = () => {
    return pinnedColumn
      ? [pinnedColumn, ...columns.filter((item) => item.id !== pinnedColumn.id)]
      : [...columns];
  };

  const [displayedColumns, setDisplayedColumns] = useState(
    getFilteredColumns(),
  );

  const moveColumn = (id: string, direction: string) => {
    setDisplayedColumns((prevColumns) => {
      const index = prevColumns.findIndex((column) => column.id === id);
      if (index === -1) return prevColumns;

      const newIndex = direction === 'left' ? index - 1 : index + 1;

      if (newIndex < 0 || newIndex >= prevColumns.length) return prevColumns;

      const updatedColumns = [...prevColumns];
      [updatedColumns[index], updatedColumns[newIndex]] = [
        updatedColumns[newIndex],
        updatedColumns[index],
      ];

      const newDisplayedColumns = [
        pinnedColumn,
        ...updatedColumns.filter((item) => item.id !== pinnedColumn.id),
      ];

      localStorage.setItem(
        'columns',
        JSON.stringify(newDisplayedColumns.map((item) => item.id)),
      );
      return newDisplayedColumns;
    });
  };

  useEffect(() => {
    const newDisplayedColumns = [
      pinnedColumn,
      ...displayedColumns.filter((item) => item.id !== pinnedColumn.id),
    ];
    pinnedColumn && setDisplayedColumns(newDisplayedColumns);

    pinnedColumn &&
      localStorage.setItem(
        'columns',
        JSON.stringify(newDisplayedColumns.map((item) => item.id)),
      );
  }, [pinnedColumn]);

  const changePinnedColumn = (column: CreatedColumnComparisonList) => {
    setPinnedColumn(column);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDifferencesHighlighted(event.target.checked);
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createColumn = (
    tableData: ComparisonListItemProps,
    index: number,
  ): CreatedColumnComparisonList => {
    return {
      id: tableData.id,
      name: tableData.name,
      track_record: tableData.track_record || '-',
      isin: tableData.isin,
      edition_date: tableData.edition_date || '-',
      volume: tableData.volume || '-',
      vote: tableData.recommendations_for_action?.vote || '-',
      rank: tableData.rank?.rank || 0,
      risk: tableData.total_risk_score || '-',
      return: tableData.return_score?.total_score?.label || '-',
      return_short_term: tableData.return_score?.short_term?.label || '-',
      return_medium_term: tableData.return_score?.medium_term?.label || '-',
      return_long_term: tableData.return_score?.long_term?.label || '-',
      return_score: tableData.return_score,
      return_pct: tableData.return_pct,
      pg_return_pct: tableData.pg_return_pct,
      return_pct_month_6: tableData.return_pct.month_6 || '-',
      return_pct_year_1: tableData.return_pct.year_1 || '-',
      return_pct_year_3: tableData.return_pct.year_3 || '-',
      return_pct_year_5: tableData.return_pct.year_5 || '-',
      return_pct_year_7: tableData.return_pct.year_7 || '-',
      return_pct_year_10: tableData.return_pct.year_10 || '-',
      volatility_score: tableData.volatility_score,
      volatility_short_term:
        tableData.volatility_score?.short_term?.label || '-',
      volatility_medium_term:
        tableData.volatility_score?.medium_term?.label || '-',
      volatility_long_term: tableData.volatility_score?.long_term?.label || '-',
      volatility: tableData.volatility,
      pg_volatility: tableData.pg_volatility,
      volatility_month_6: tableData.volatility.month_6 || '-',
      volatility_year_1: tableData.volatility.year_1 || '-',
      volatility_year_3: tableData.volatility.year_3 || '-',
      volatility_year_5: tableData.volatility.year_5 || '-',
      volatility_year_7: tableData.volatility.year_7 || '-',
      volatility_year_10: tableData.volatility.year_10 || '-',
      total_active_management: tableData.total_active_management_score || '-',
      credibility_score:
        tableData.esg_scores?.credibility_score?.toString() || '-',
      action_score_firm:
        tableData.esg_scores?.action_score_firm?.toString() || '-',
      action_score_fund:
        tableData.esg_scores?.action_score_fund?.toString() || '-',
      issuer: tableData.issuer || '-',
      ongoing_charge: tableData.ongoing_charge
        ? `${formatNumberWithComma(tableData.ongoing_charge)} %`
        : '-',
      performance_fee: tableData.performance_fee
        ? `${tableData.performance_fee} %`
        : '-',
      minimum_investment:
        tableData.min_investment && tableData.min_investment_currency
          ? formatCurrency(
              tableData.min_investment,
              tableData.min_investment_currency,
            )
          : '-',
      isInFundList: tableData.is_favoured,
      isInComparison: true,
      color: backgroundColors[index],
      peer_group_name: tableData.peer_group_name || '-',
      tracking_error: tableData.tracking_error || '-',
      management_affiliation: tableData.management_affiliation || '-',
    };
  };

  const sortArrayByIds = (
    ids: string[],
    items: CreatedColumnComparisonList[],
  ): CreatedColumnComparisonList[] => {
    const idOrderMap = new Map<string, number>();
    ids.forEach((id, index) => idOrderMap.set(id, index));

    return items.sort((a, b) => {
      const orderA = idOrderMap.get(a.id) ?? Infinity;
      const orderB = idOrderMap.get(b.id) ?? Infinity;
      return orderA - orderB;
    });
  };

  const getData = async () => {
    const response = await fetchSmartBenchmarkingComparisonList();
    if (response.ok) {
      const tableData = response.parsedBody;
      const newData = tableData.map((data, index) => createColumn(data, index));
      const storedColumns = localStorage.getItem('columns');
      const sortedArray = sortArrayByIds(
        storedColumns ? JSON.parse(storedColumns) : [],
        newData,
      );
      if (sortedArray.length === 0) {
        setDisplayedColumns(newData);
        setColumns(newData);
        newData[0] && setPinnedColumn(newData[0]);
      } else {
        setDisplayedColumns(sortedArray);
        setColumns(sortedArray);
        sortedArray[0] && setPinnedColumn(sortedArray[0]);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const showAddFeedbackModal = (id: string, name: string) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name });
    }
  };

  const showConfirmRemoveFromFundListModal = (id: string, name: string) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name });
  };

  const showConfirmRemoveFromComparisonListModal = (
    id: string,
    name: string,
  ) => {
    setIsConfirmRemoveFromComparisonOpen(true);
    setCurrentFund({ id, name });
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatusSmartBenchmarking(id);
    if (response.ok) {
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
      getData();
    }
  };

  const addFromComparisonList = (id: string, name: string) => {
    raiseToast.addToComparisonList(
      <Toast
        title="Fonds zu Ihrer Vergleichsliste hinzugefügt"
        content={`"${name}" wurde zu Ihrer Vergleichsliste hinzugefügt.`}
      />,
    );
    getData();
  };

  const removeFromComparisonList = (id: string, name: string) => {
    setIsConfirmRemoveFromComparisonOpen(false);

    raiseToast.removeFromComparisonList(
      <Toast
        title="Fonds von Ihrer Vergleichsliste entfernt"
        content={`"${name}" wurde aus Ihrer Vergleichsliste entfernt.`}
      />,
    );

    const newDisplayedColumns = displayedColumns.filter(
      (item) => item.id !== id,
    );
    if (displayedColumns.length !== 0 && id === pinnedColumn.id) {
      newDisplayedColumns && changePinnedColumn(newDisplayedColumns[0]);
    }
    setDisplayedColumns(newDisplayedColumns);
    localStorage.setItem(
      'columns',
      JSON.stringify(newDisplayedColumns.map((item) => item.id)),
    );

    if (displayedColumns.length === 0) {
      getData();
      setColumns([]);
    }
  };

  const changeComparisonListStatus = async (id: string, name: string) => {
    profile?.fetchProfileData();
    const response = await fetchUpdateComparisonListStatusSmartBenchmarking(id);
    if (response.ok) {
      isConfirmRemoveFromComparisonOpen
        ? removeFromComparisonList(id, name)
        : addFromComparisonList(id, name);
    }
  };

  return (
    <>
      <AddFeedbackModal
        withInterestLevel
        variant="smart-benchmarking"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <RemoveFromComparisonListModal
        isShown={isConfirmRemoveFromComparisonOpen}
        toggle={() => setIsConfirmRemoveFromComparisonOpen(false)}
        onSubmit={() =>
          changeComparisonListStatus(currentFund.id, currentFund.name)
        }
      />
      {displayedColumns.length === 0 ? (
        <Box pt={5} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body">Keine Fonds gefunden</Typography>
        </Box>
      ) : (
        <StyledContainer pt={5} pb={22}>
          <Box
            px={5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchContainer>
              <ComparisonListSearch
                menuOpen={searchOpen}
                setMenuOpen={setSearchOpen}
                addToComparison={changeComparisonListStatus}
              />
            </SearchContainer>
            <Switch
              checked={isDifferencesHighlighted}
              onChange={handleSwitchChange}
              label="Unterschiede Hervorheben"
            />
          </Box>
          <Box pl={5} pt={0.5}>
            <VerticalCollapseTable
              isDifferencesHighlighted={isDifferencesHighlighted}
              tableData={{
                columns: displayedColumns,
                rows: rows as CreatedRowComparisonList[],
              }}
              pinnedColumn={pinnedColumn}
              showConfirmRemoveFromFundListModal={
                showConfirmRemoveFromFundListModal
              }
              changeFundListStatus={changeFundListStatus}
              requestReport={requestReport}
              showAddFeedbackModal={showAddFeedbackModal}
              showConfirmRemoveFromComparisonListModal={
                showConfirmRemoveFromComparisonListModal
              }
              changePinnedColumn={changePinnedColumn}
              moveColumn={moveColumn}
            />
          </Box>
          <Box px={5} pt={5}>
            <ComparisonListChart data={displayedColumns} />
          </Box>
        </StyledContainer>
      )}
    </>
  );
};
