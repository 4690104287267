import { FC } from 'react';
import { PurchasePriceIndicatorProps } from './PurchasePriceIndicator.d';
import { StyledContainer } from './PurchasePriceIndicator.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';

export const PurchasePriceIndicator: FC<PurchasePriceIndicatorProps> = ({
  value,
}) => {
  if (value === null) return <>-</>;

  return (
    <Box display="flex" gap={1} justifyContent="center" alignItems="center">
      <Typography variant="body">t</Typography>
      <StyledContainer value={value}>+{value}</StyledContainer>
    </Box>
  );
};
