import { forwardRef } from 'react';
import { AcceptRequestWithCommentModalProps } from './AcceptRequestWithCommentModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledComment,
  StyledContainer,
  StyledContent,
  StyledDate,
  StyledHeadline,
  StyledIsin,
  StyledSeparator,
} from './AcceptRequestWithCommentModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { parseDateTime } from 'utils/common';

export const AcceptRequestWithCommentModal = forwardRef<
  HTMLDivElement,
  AcceptRequestWithCommentModalProps
>(
  (
    {
      isShown,
      toggle,
      title,
      subtitle,
      fundName,
      isin,
      isWarning = false,
      requestedBy,
      requestDate,
      comment,
      confirmButtonText = 'Ja',
      cancelButtonText = 'Abbrechen',
      onSubmit,
      onCancelButtonClick,
    },
    ref,
  ) => {
    const formattedDate = parseDateTime(requestDate).date;
    const formattedTime = parseDateTime(requestDate).time;

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={title}
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          {!!subtitle?.length && (
            <Typography variant="body" color={COLORS.typography.description}>
              {subtitle}
            </Typography>
          )}
          <StyledContent>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h5">{fundName}</Typography>
              <StyledIsin variant="body">{isin}</StyledIsin>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} pt={4}>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <StyledHeadline variant="overline">
                  Angefordert von
                </StyledHeadline>
                <Box display="flex" alignItems="center" gap={2}>
                  <LetterBadge
                    value={requestedBy[0]}
                    size="small"
                    color={COLORS.background.secondary}
                    backgroundColor={COLORS.info.selectedBorder}
                    borderColor={COLORS.info.selectedBorder}
                  />
                  <Box flexDirection="column" gap={1}>
                    <Typography variant="body" weight="bold">
                      {requestedBy}
                    </Typography>
                    <Box display="flex" gap={0.7} alignItems="center">
                      <StyledDate variant="body">{formattedDate}</StyledDate>
                      <StyledSeparator />
                      <StyledDate variant="body">{formattedTime}</StyledDate>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <StyledHeadline variant="overline">Kommentar</StyledHeadline>
                {comment && (
                  <StyledComment variant="body">{comment}</StyledComment>
                )}
              </Box>
            </Box>
          </StyledContent>
          <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={onCancelButtonClick || toggle}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant={
                isWarning ? ButtonVariant.WARNING : ButtonVariant.PRIMARY
              }
              type="submit"
              onClick={onSubmit}
            >
              {confirmButtonText}
            </Button>
          </Box>
        </StyledContainer>
      </Modal>
    );
  },
);
