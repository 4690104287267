import { FC, PropsWithChildren, ReactNode } from 'react';
import { ModuleLayoutProps } from './ModuleLayout.d';
import {
  SearchContainer,
  StyledContainer,
  StyledExportButton,
  StyledSelectContainer,
} from './ModuleLayout.styled';
import { Box } from 'components/common/Box';
import { Search } from 'components/common/Search';
import { FiltersButton } from 'components/common/Buttons/FiltersButton';
import { ReactComponent as ExportIcon } from 'assets/Icons/import.svg';
import { CustomSelect } from 'components/common/Select';
import { SelectChangeEvent } from '@mui/material';

export const ModuleLayout: FC<PropsWithChildren<ModuleLayoutProps>> = ({
  children,
  searchValue,
  searchPlaceholder,
  portfoliosOptions,
  selectedPortfolio,
  setSelectedPortfolio,
  activeFiltersAmount,
  onSubmitSearch,
  onToggleFilters,
  onExportClick,
  withTabs = false,
  ...props
}) => {
  const handleSelectChange = (
    event: SelectChangeEvent<any>,
    _child: ReactNode,
  ) => {
    if (portfoliosOptions && setSelectedPortfolio) {
      const newValue = event.target.value.value;
      const [newFilter] = portfoliosOptions.filter(
        (item) => item.value === newValue,
      );
      newFilter && setSelectedPortfolio(newFilter);
    }
  };

  return (
    <StyledContainer
      withTabs={withTabs}
      pt={5}
      display="flex"
      flexDirection="column"
      gap={3}
      {...props}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          {!!portfoliosOptions?.length && setSelectedPortfolio && (
            <StyledSelectContainer>
              <CustomSelect
                selectVariant="filled"
                options={portfoliosOptions}
                value={selectedPortfolio || null}
                action={handleSelectChange}
                placeholder="Portfolios"
              />
            </StyledSelectContainer>
          )}
          <SearchContainer>
            <Search
              withoutLeftBorderRadius={!!portfoliosOptions}
              initialValue={searchValue}
              onSubmit={onSubmitSearch}
              placeholder={searchPlaceholder}
            />
          </SearchContainer>
        </Box>
        {/* <Box display="flex" alignItems="center" gap={2}>
          {activeFiltersAmount !== null && (
            <FiltersButton
              activeFiltersAmount={activeFiltersAmount}
              onClick={onToggleFilters}
            />
          )}
          {onExportClick && (
            <StyledExportButton
              rightIcon={<ExportIcon />}
              onClick={onExportClick}
            >
              Exportieren
            </StyledExportButton>
          )}
        </Box> */}
      </Box>
      {children}
    </StyledContainer>
  );
};
