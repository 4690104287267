import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledContainerProps } from './ModuleLayout.d';
import { Button } from 'components/common/Buttons/Button';
import { Typography } from 'components/common/Typography';

export const StyledContainer = styled(Box)<StyledContainerProps>`
  background-color: ${COLORS.background.module};
  border-radius: ${SIZES.spacing(2)};

  ${({ withTabs }) => withTabs && 'border-top-left-radius: 0;'}
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 686px;

  ${SIZES.media.tablet} {
    width: 555px;
  }
`;

export const StyledExportButton = styled(Button)`
  padding: 11px 24px;
`;

export const StyledSelectContainer = styled(Box)`
  width: 180px;
`;

export const StyledTooltipContent = styled(Typography)`
  font-size: 13px;
  line-height: 17px;
  color: ${COLORS.background.neutral};
`;
