import { FC, useState } from 'react';
import { ActionsProps } from './Actions.d';
import {
  StyledContainer,
  StyledIconButton,
  StyledMoreActionsButton,
  StyledSuggestActionContainer,
  StyledTooltipContent,
  StyledTooltipHeader,
} from './Actions.styled';
import { ReactComponent as DownloladIcon } from 'assets/Icons/download.svg';
import { ReactComponent as CompareIcon } from 'assets/Icons/add-to-compare.svg';
import { ReactComponent as AddToFundIcon } from 'assets/Icons/add-fund.svg';
import { ReactComponent as RemoveFromFundIcon } from 'assets/Icons/remove-from-fund-list.svg';
import { ReactComponent as FeedbackIcon } from 'assets/Icons/feedback.svg';
import { ReactComponent as FolderIcon } from 'assets/Icons/folder-cross.svg';
import { ReactComponent as FolderAddIcon } from 'assets/Icons/folder-add.svg';
import { ReactComponent as MoreActionsIcon } from 'assets/Icons/more-actions.svg';
import { ReactComponent as AcceptIcon } from 'assets/Icons/verify.svg';
import { ReactComponent as RejectIcon } from 'assets/Icons/minus-circle.svg';
import { COLORS } from 'theme/colors';
import { CustomTooltip } from 'components/common/Tooltip';
import { ExpandedActions } from './ExpandedActions';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactOverviewAction,
} from 'utils/google-analytics/events/contacts';
import { AppPaths } from 'urls/frontend';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const Actions: FC<ActionsProps> = ({
  menuWidth,
  isInMyFundList,
  isInComparisonList = false,
  isInMyPortfolio = false,
  isCompassDesignRequestSent = false,
  isPresentInKompass = false,
  requestTypeInCompassDesign,
  disabledToRemoveFromPortfolio,
  variant = 'full',
  requestReportText,
  onChangeFundListHandle,
  onRequestReportHandler,
  onFeedbackHandle,
  onChangeComparisonListHandle,
  onChangePortfolioHandle,
  onChangeCompasStatusHandle,
  onFundUpdateHandle,
  onRequestFundUpdateHandle,
  onPlansecurCommentsHandle,
  onFundPortraitHandle,
  onRequestCompetitiveAnalysisHandle,
  onOtherRequestHandle,
  onChangeRequestStatusInCompassDesign,
  ...props
}) => {
  const isShortVariant = variant === 'short';
  const { customNavigate } = useNavigation();
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(moreActionsAnchorEl);

  const handleClickMoreActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactOverviewAction,
      window.location.pathname,
    );
    customNavigate(AppPaths.contuctUs);
  };

  return (
    <StyledContainer
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent="flex-end"
      mr={0.5}
      {...props}
    >
      {onChangeFundListHandle && (
        <CustomTooltip
          title={
            isInMyFundList
              ? 'Aus meiner Fondsliste entfernen'
              : 'Zu Meine Fondsliste hinzufügen'
          }
          placement="left"
        >
          <StyledIconButton onClick={onChangeFundListHandle}>
            {isInMyFundList ? (
              <RemoveFromFundIcon fill={COLORS.error.background} />
            ) : (
              <AddToFundIcon fill={COLORS.accent.primary} />
            )}
          </StyledIconButton>
        </CustomTooltip>
      )}
      {onChangePortfolioHandle && (
        <CustomTooltip
          title={
            isInMyPortfolio
              ? 'Von der Watchlist löschen'
              : 'Zur Watchlist hinzufügen'
          }
          placement="left"
          disableHoverListener={disabledToRemoveFromPortfolio}
        >
          <StyledIconButton
            disabled={disabledToRemoveFromPortfolio}
            onClick={onChangePortfolioHandle}
          >
            {isInMyPortfolio ? (
              <RemoveFromFundIcon fill={COLORS.error.background} />
            ) : disabledToRemoveFromPortfolio ? (
              <RemoveFromFundIcon fill={COLORS.stroke.primary} />
            ) : (
              <AddToFundIcon fill={COLORS.accent.primary} />
            )}
          </StyledIconButton>
        </CustomTooltip>
      )}
      {requestTypeInCompassDesign && onChangeRequestStatusInCompassDesign && (
        <CustomTooltip
          title={
            requestTypeInCompassDesign === 'Addition'
              ? 'Vorschlag genehmigen'
              : 'Vorschlag ablehnen'
          }
          placement="left"
        >
          <StyledIconButton
            onClick={(e) =>
              onChangeRequestStatusInCompassDesign(
                e,
                requestTypeInCompassDesign,
              )
            }
          >
            {requestTypeInCompassDesign &&
            requestTypeInCompassDesign === 'Addition' ? (
              <AcceptIcon />
            ) : (
              <RejectIcon fill={COLORS.error.background} />
            )}
          </StyledIconButton>
        </CustomTooltip>
      )}
      {!isShortVariant && onFundPortraitHandle && (
        <CustomTooltip title="Fondsporträt" placement="top">
          <StyledIconButton onClick={onFundPortraitHandle}>
            <DownloladIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {onChangeCompasStatusHandle && (
        <CustomTooltip
          title={
            isPresentInKompass
              ? 'Aus Kompass-Entwurf entfernen'
              : 'In Kompass-Entwurf aufnehmen'
          }
          placement="left"
        >
          <StyledIconButton onClick={onChangeCompasStatusHandle}>
            {isPresentInKompass ? (
              <FolderIcon fill={COLORS.error.background} />
            ) : (
              <FolderAddIcon fill={COLORS.info.selectedBorder} />
            )}
          </StyledIconButton>
        </CustomTooltip>
      )}
      {!isShortVariant && onChangeComparisonListHandle && (
        <CustomTooltip
          title={
            isInComparisonList
              ? 'Aus meiner Vergleichsliste entfernen'
              : 'Zu Meiner Vergleichsliste hinzufügen'
          }
          placement="left"
        >
          <StyledIconButton onClick={onChangeComparisonListHandle}>
            <CompareIcon
              fill={
                isInComparisonList
                  ? COLORS.accent.primary
                  : COLORS.error.background
              }
            />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {!isShortVariant && onRequestReportHandler && (
        <CustomTooltip
          title={requestReportText || 'FondsConsult Fondsporträt anfordern'}
          placement="left"
        >
          <StyledIconButton onClick={onRequestReportHandler}>
            <DownloladIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {onFeedbackHandle && (
        <CustomTooltip title="Feedback geben" placement="left">
          <StyledIconButton onClick={onFeedbackHandle}>
            <FeedbackIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {isShortVariant && (
        <StyledMoreActionsButton onClick={handleClickMoreActions}>
          <MoreActionsIcon
            stroke={
              openMoreActions ? COLORS.accent.primary : COLORS.typography.main
            }
          />
        </StyledMoreActionsButton>
      )}
      <ExpandedActions
        menuWidth={menuWidth}
        isInMyFundList={isInMyFundList}
        isInComparisonList={isInComparisonList}
        isInMyPortfolio={isInMyPortfolio}
        requestReportText={requestReportText}
        open={openMoreActions}
        setAnchorEl={setMoreActionsAnchorEl}
        anchorEl={moreActionsAnchorEl}
        onChangeComparisonListHandle={onChangeComparisonListHandle}
        onDownloadHandler={onRequestReportHandler}
        onFundUpdateHandle={onFundUpdateHandle}
        onRequestFundUpdateHandle={onRequestFundUpdateHandle}
        onPlansecurCommentsHandle={onPlansecurCommentsHandle}
        onFundPortraitHandle={onFundPortraitHandle}
        onRequestCompetitiveAnalysisHandle={onRequestCompetitiveAnalysisHandle}
        onOtherRequestHandle={onOtherRequestHandle}
        // onChangePortfolioHandle={onChangePortfolioHandle}
      />
    </StyledContainer>
  );
};
