import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { Button } from 'components/common/Buttons/Button';
import { Typography } from 'components/common/Typography';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(2)} ${SIZES.spacing(3)} ${SIZES.spacing(3)};
  background-color: ${COLORS.background.secondary};
  border-radius: 16px;
  width: 100%;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.typography.main};
  width: 16px;
  height: 16px;
  transform: rotate(135deg);
`;

export const StyledButton = styled(Button)`
  padding: 11px 15px;
  min-width: 136px;
`;

export const StyledName = styled(Typography)`
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  color: ${COLORS.typography.primary};
`;
