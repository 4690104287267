import React, { FC, useRef, useState } from 'react';
import { NavigationItemProps, NavigationMenuProps } from './NavigationMenu.d';
import {
  StyledCheckbox,
  StyledStripe,
  StyledContainer,
  StyledLinks,
  MenuLinkItem,
  MenuSectionName,
  StyledLink,
  StyledLogoutLink,
  StyledLogoutContainer,
  StyledDot,
  StyledLinksContainer,
  StyledOverlay,
} from './NavigationMenu.styled';
import { useClickOutside } from 'hooks/useClickOutside';
import { Box } from '../Box';
import { ReactComponent as Logo } from 'assets/logo-white.svg';
import { ReactComponent as LogoutIcon } from 'assets/Icons/logout.svg';
import { ReactComponent as MyFundListIcon } from 'assets/Icons/my-fund-list-menu.svg';
import { ReactComponent as ComparisonListIcon } from 'assets/Icons/comparison-list-menu.svg';
import { ReactComponent as MLPDepotsIcon } from 'assets/Icons/mlp-depots-menu.svg';
import { ReactComponent as AnalystServiceDeskIcon } from 'assets/Icons/analyst-service-desk.svg';
import { CloseButton } from '../Buttons/CloseButton';
import { COLORS } from 'theme/colors';
import { Chip } from '../Chip';
import { useLocation } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { useAuth } from 'context/Auth';
import { useProfile } from 'context/Profile';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const NavigationMenu: FC<NavigationMenuProps> = ({ variant }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const profile = useProfile();
  const auth = useAuth();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navbarItems: NavigationItemProps[] = [
    {
      categoryName: '',
      items: [
        { name: 'Home', link: '/' },
        {
          name: 'Handlungsempfehlungen für Fonds',
          link: AppPaths.recommendationsForActions,
        },
        {
          name: 'Private Markets & Real Estate',
          link: AppPaths.privateMarketsRealEstate,
        },
        {
          name: 'ESG Credibility & Action Score',
          link: AppPaths.esgCredibilityActionScore,
        },
        {
          name: 'FC Smart Fund Benchmarking',
          link: AppPaths.fcSmartFundBenchmarking,
        },
        { name: 'FondsConsult Studien', link: AppPaths.fondsConsultStudies },
        {
          name: 'Managerprofile und -einschätzung',
          link: '#',
          availableFrom: 'August 2024',
        },
        {
          name: 'Marktanalyse',
          link: '#',
          availableFrom: 'August 2024',
        },
      ],
    },
    {
      categoryName: 'Persönlicher Bereich',
      items: [
        {
          name: 'Plansecur Produktkompass',
          link: AppPaths.customerOverview,
          icon: <MLPDepotsIcon stroke={COLORS.info.background} />,
        },
        ...(profile?.profileInfo?.mlp_depots_access
          ? [
              {
                name: 'MLP Depots',
                link: AppPaths.mlpDepots,
                icon: <MLPDepotsIcon stroke={COLORS.info.background} />,
              },
            ]
          : []),
        {
          name: 'Meine Fondsliste',
          link: AppPaths.myWatchlist,
          icon: <MyFundListIcon stroke={COLORS.info.background} />,
        },
        {
          name: 'Mein Fondsvergleich',
          link: AppPaths.comparisonList,
          icon: <ComparisonListIcon stroke={COLORS.info.background} />,
        },
      ],
    },
    {
      categoryName: 'Support',
      items: [
        {
          name: 'Analyst Service Desk',
          link: AppPaths.contuctUs,
          icon: <AnalystServiceDeskIcon stroke={COLORS.info.background} />,
        },
      ],
    },
  ];

  const handleCloseMenu = () => {
    setOpen(!open);
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const logout = async () => {
    await auth?.logout();
    customNavigate(AppPaths.login);
  };

  return (
    <nav>
      <StyledContainer ref={wrapperRef}>
        <StyledCheckbox
          type="checkbox"
          checked={open}
          onClick={handleCloseMenu}
          variant={variant}
        />
        <StyledStripe variant={variant} />
        <StyledStripe variant={variant} />
        <StyledStripe variant={variant} />
        <StyledLinks>
          <Box
            pt={3}
            pl={6.5}
            pr={5}
            display="flex"
            justifyContent="space-between"
          >
            <Logo />
            <CloseButton onClick={handleCloseMenu} />
          </Box>
          <StyledLinksContainer
            pl={1.2}
            pt={1.5}
            pb={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column" style={{ gap: '13px' }}>
              {navbarItems.map((category, index) => (
                <React.Fragment key={`${category}-${index}`}>
                  {category.categoryName && (
                    <MenuSectionName
                      variant="overline"
                      weight="semibold"
                      color={COLORS.background.secondary}
                    >
                      {category.categoryName}
                    </MenuSectionName>
                  )}
                  {category.items.map((item) => {
                    const isCurrentPage = pathname === item.link;
                    return (
                      <MenuLinkItem
                        key={item.link}
                        isClickable={!item.availableFrom}
                        isCurrent={isCurrentPage}
                      >
                        {isCurrentPage && <StyledDot />}
                        {item.icon && item.icon}
                        <StyledLink
                          to={item.link}
                          state={{ prevPath: pathname || AppPaths.main }}
                          isClickable={!item.availableFrom}
                          isCurrent={isCurrentPage}
                          onClick={() => customNavigate(item.link)}
                        >
                          {item.name}
                        </StyledLink>
                        {!!item.availableFrom && (
                          <Chip label="Bald verfügbar" />
                        )}
                      </MenuLinkItem>
                    );
                  })}
                </React.Fragment>
              ))}
            </Box>
            <StyledLogoutContainer display="flex" gap={1} alignItems="center">
              <LogoutIcon stroke={COLORS.info.main} />
              <StyledLogoutLink onClick={logout}>Abmelden</StyledLogoutLink>
            </StyledLogoutContainer>
          </StyledLinksContainer>
        </StyledLinks>
      </StyledContainer>
      {open && <StyledOverlay />}
    </nav>
  );
};
