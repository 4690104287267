import { SelectCellProps } from './SelectCell.d';
import {
  StyledHeadSelectCell,
  StyledIconButton,
  StyledSelectCell,
} from './SelectCell.styled';
import { ReactComponent as CheckBoxIcon } from 'assets/Icons/table-checkbox.svg';
import { ReactComponent as CheckedCheckBoxIcon } from 'assets/Icons/checked-checkbox.svg';
import { ReactComponent as SelectAllCheckBoxIcon } from 'assets/Icons/select-all-checkbox.svg';

export const SelectCell = ({
  isChecked,
  isSelectAll = false,
  variant = 'table-cell',
  highlightColor,
  setIsChecked,
  width,
}: SelectCellProps) => {
  const innerContent = (
    <StyledIconButton onClick={setIsChecked}>
      {isSelectAll ? (
        <SelectAllCheckBoxIcon />
      ) : isChecked ? (
        <CheckedCheckBoxIcon />
      ) : (
        <CheckBoxIcon />
      )}
    </StyledIconButton>
  );

  return (
    <>
      {variant === 'table-head' ? (
        <StyledHeadSelectCell
          isLastColumnsFixed
          style={{
            width,
            minWidth: width,
            maxWidth: width,
          }}
        >
          {innerContent}
        </StyledHeadSelectCell>
      ) : (
        <StyledSelectCell
          isLastColumnsFixed
          selected={isChecked}
          highlightColor={highlightColor}
        >
          {innerContent}
        </StyledSelectCell>
      )}
    </>
  );
};
