import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { commonColumns, createRow } from '../config';
import {
  fetchAcceptRequestToChangeCompass,
  fetchKompassDesign,
  fetchPlansecurFund,
} from 'services/Plansecur';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { RequestedByColumn } from 'components/Modules/RequestedByColumn';
import { useProfile } from 'context/Profile';
import { AcceptRequestWithCommentModal } from '../Modals/AcceptRequestWithCommentModal';
import { parseDateTime } from 'utils/common';
import { raiseToast } from 'components/common/Toast/raiseToast';

export const CompassDesign: FC = () => {
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const { search } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [isAcceptRequestAddToCompassOpen, setIsAcceptRequestAddToCompassOpen] =
    useState(false);
  const [
    isAcceptRequestRemoveFromCompassOpen,
    setIsAcceptRequestRemoveFromCompassOpen,
  ] = useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
    user__name: '',
    created_at: '',
    comment: '',
    request_id: '',
  });

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const requestedByColumn: ColumnProps = {
    id: 'requests',
    label: 'Anträge zur Aufnahme / Streichung',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'user__name',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Beantragt von',
        sortable: true,
        tooltip: 'First return value',
        minWidth: '170px',
        maxWidth: '180px',
        renderCell: ({ value }) => <RequestedByColumn name={value} />,
      },
      {
        id: 'created_at',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Datum',
        sortable: true,
        tooltip: 'Date',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <>{parseDateTime(value).date}</>,
      },
      {
        id: 'requested_by_comment',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Kommentar',
        sortable: false,
        tooltip: 'Comment',
        minWidth: '285px',
        maxWidth: '285px',
      },
    ],
  };

  const showConfirmRequestModal = (
    requestType: 'Addition' | 'Removal',
    id: string,
    name: string,
    isin: string,
    user__name: string,
    created_at: string,
    comment: string,
    requestId: string,
  ) => {
    setCurrentFund({
      id,
      name,
      isin,
      user__name,
      created_at,
      comment,
      request_id: requestId,
    });
    requestType === 'Addition'
      ? setIsAcceptRequestAddToCompassOpen(true)
      : setIsAcceptRequestRemoveFromCompassOpen(true);
  };

  const toggleConfirmRequestModal = () => {
    isAcceptRequestAddToCompassOpen
      ? setIsAcceptRequestAddToCompassOpen(!isAcceptRequestAddToCompassOpen)
      : setIsAcceptRequestRemoveFromCompassOpen(
          !isAcceptRequestRemoveFromCompassOpen,
        );
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isAdmin || isPlansecurAdmin
      ? [
          {
            id: 'actions',
            label: 'Aktionen',
            tooltip: 'Actions',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                justifyContent="center"
                mr={0}
                requestTypeInCompassDesign={row.requestType}
                onRemoveFromCompassDesign={() => {}}
                onChangeRequestStatusInCompassDesign={
                  isPlansecurAdmin
                    ? (
                        e: React.BaseSyntheticEvent,
                        requestType: 'Addition' | 'Removal',
                      ) => {
                        e.stopPropagation();
                        showConfirmRequestModal(
                          requestType,
                          row.id,
                          row.name,
                          row.isin,
                          row.user__name,
                          row.created_at,
                          row.requested_by_comment ?? '-',
                          row.requestId ?? '',
                        );
                      }
                    : undefined
                }
              />
            ),
            width: '58px',
            subColumns: [],
          },
        ]
      : []),
  ];

  const getTableData = async () => {
    const response = await fetchKompassDesign({
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? {
            sort_by:
              sortModel.sort_by === 'created_at' ||
              sortModel.sort_by === 'user__name'
                ? sortModel.sort_by
                : `fund__${sortModel.sort_by}`,
            sort_order: sortModel.sort_order,
          }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    });
    if (response.ok) {
      const tableData = response.parsedBody.results.requests.map((item) => ({
        user__name: `${item.user.name} ${item.user.last_name}`,
        created_at: item.created_at,
        requested_by_comment: item.notes,
        requestType: item.type,
        request_id: item.id,
        ...item.fund,
      }));
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => true);
    // .some((row) => !row.is_favoured);
  };

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const sendAcceptRequest = async (
    requestId: string,
    requestStatus: 'Accepted' | 'Rejected',
  ) => {
    const response = await fetchAcceptRequestToChangeCompass({
      id: requestId,
      status: requestStatus,
    });
    if (response.ok) {
      getTableData();
      isAcceptRequestAddToCompassOpen &&
        setIsAcceptRequestAddToCompassOpen(false);
      isAcceptRequestRemoveFromCompassOpen &&
        setIsAcceptRequestRemoveFromCompassOpen(false);

      if (requestStatus === 'Accepted') {
        isAcceptRequestAddToCompassOpen &&
          raiseToast.send(
            'Die Anfrage, den Fonds zum Kompass hinzuzufügen, wurde akzeptiert.',
          );
        isAcceptRequestRemoveFromCompassOpen &&
          raiseToast.send(
            'Die Anfrage, den Fonds aus dem Kompass zu entfernen, wurde akzeptiert.',
          );
      } else {
        isAcceptRequestAddToCompassOpen &&
          raiseToast.error(
            'Die Anfrage, den Fonds zum Kompass hinzuzufügen, wurde abgelehnt.',
          );
        isAcceptRequestRemoveFromCompassOpen &&
          raiseToast.error(
            'Die Anfrage, den Fonds aus dem Kompass zu entfernen, wurde abgelehnt.',
          );
      }
    }
  };

  return (
    <>
      {currentFund.id && (
        <AcceptRequestWithCommentModal
          isShown={
            isAcceptRequestAddToCompassOpen ||
            isAcceptRequestRemoveFromCompassOpen
          }
          isWarning={isAcceptRequestRemoveFromCompassOpen}
          title={
            isAcceptRequestAddToCompassOpen
              ? 'Vorschlag genehmigen'
              : 'Vorschlag ablehnen'
          }
          subtitle={
            isAcceptRequestAddToCompassOpen
              ? 'Sind Sie sicher, dass Sie die Anfrage akzeptieren möchten, um diesen Fonds zum Kompass hinzuzufügen?'
              : 'Sind Sie sicher, dass Sie die Anfrage zur Löschung dieses Fonds aus dem Kompass akzeptieren möchten?'
          }
          confirmButtonText={
            isAcceptRequestAddToCompassOpen ? 'Ja, Akzeptieren' : 'Ja, Ablehnen'
          }
          cancelButtonText="Abbrechen"
          fundName={currentFund.name}
          isin={currentFund.isin}
          toggle={toggleConfirmRequestModal}
          onCancelButtonClick={() =>
            sendAcceptRequest(currentFund.request_id, 'Rejected')
          }
          onSubmit={() => sendAcceptRequest(currentFund.request_id, 'Accepted')}
          requestedBy={currentFund.user__name}
          requestDate={currentFund.created_at}
          comment={currentFund.comment}
        />
      )}
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        {/* <SelectedFilters filters={[]} /> */}
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
              handleAddToPortfolio={() => {}}
              isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isAdmin || isPlansecurAdmin}
            loading={loading}
            tableData={{
              rows,
              columns: [columns[0], requestedByColumn, ...columns.slice(1)],
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
