import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { Option } from 'components/common/Select/Select.d';
import { commonColumns, createRow } from '../config';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchPlansecurFund,
  fetchPortfolios,
  fetchUpdateFundPortfolioStatus,
  sendRequestFundPortfolio,
} from 'services/Plansecur';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { ConfirmWithCommentModal } from '../Modals/ConfirmWithCommentModal';
import { AcceptRequestWithCommentModal } from '../Modals/AcceptRequestWithCommentModal';
import { CommentsModal } from '../Modals/CommentsModal/CommentsModal';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { useProfile } from 'context/Profile';
import { TableChip } from 'components/common/TableChip';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { StyledTableChipContainer } from '../CustomerOverview.styled';

export const Portfolios: FC = () => {
  const { search } = useLocation();
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const assignedPortfolioPate = profile?.profileInfo?.assigned_portfolio_pate;
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [portfoliosOptions, setPortfoliosOptions] = useState<Option[]>([
    ...(assignedPortfolioPate
      ? [
          {
            value: assignedPortfolioPate?.id,
            name: assignedPortfolioPate?.name,
          },
        ]
      : []),
  ]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<Option | null>(
    assignedPortfolioPate
      ? {
          value: assignedPortfolioPate?.id,
          name: assignedPortfolioPate?.name,
        }
      : null,
  );
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmRemoveFromPortfolioOpen,
    setIsConfirmRemoveFromPortfolioOpen,
  ] = useState(false);
  const [isConfirmAddToCompassOpen, setIsConfirmAddToCompassOpen] =
    useState(false);
  const [isConfirmRemoveFromCompassOpen, setIsConfirmRemoveFromCompassOpen] =
    useState(false);
  const [
    isConfirmRequestFundPortraitOpen,
    setIsConfirmRequestFundPortraitOpen,
  ] = useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
  });

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const showConfirmRemoveFromPortfolioModal = (
    name: string,
    id: string,
    isin: string,
  ) => {
    setIsConfirmRemoveFromPortfolioOpen(true);
    setCurrentFund({ id, name, isin });
  };

  const showConfirmRemoveFromCompassModal = (
    name: string,
    id: string,
    isin: string,
  ) => {
    setIsConfirmRemoveFromCompassOpen(true);
    setCurrentFund({ id, name, isin });
  };

  const changeFundPortfolioStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundPortfolioStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromPortfolioOpen &&
        setIsConfirmRemoveFromPortfolioOpen(false);
      isConfirmRemoveFromPortfolioOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt" // edit message
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const onChangeCompasStatusHandle = ({
    id,
    name,
    isin,
    present_in_kompass,
  }: RowProps) => {
    present_in_kompass
      ? setIsConfirmRemoveFromCompassOpen(true)
      : setIsConfirmAddToCompassOpen(true);

    setCurrentFund({ id, name, isin });
  };

  // const changeFundCompassStatus = async (id: string, name: string) => {
  //   const response = await fetchUpdateFundPortfolioStatus(id);
  //   if (response.ok) {
  //     getTableData();
  //     isConfirmRemoveFromCompassOpen &&
  //       setIsConfirmRemoveFromCompassOpen(false);
  //     isConfirmRemoveFromCompassOpen
  //       ? raiseToast.removeFromFundList(
  //           <Toast
  //             title="Fonds aus Ihrer Fondsliste entfernt" // edit message
  //             content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
  //           />,
  //         )
  //       : raiseToast.addToFundList(
  //           <Toast
  //             title="Fonds zu Ihrer Fondsliste hinzugefügt"
  //             content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
  //           />,
  //         );
  //   }
  // };

  const sendRequestFundPortrait = async (id: string, name: string) => {
    const response = await sendRequestFundPortfolio(id);
    if (response.ok) {
      getTableData();
      isConfirmRequestFundPortraitOpen &&
        setIsConfirmRequestFundPortraitOpen(false);
      isConfirmRequestFundPortraitOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt" // edit message
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const statusColumn: ColumnProps = {
    id: 'status_column',
    label: 'Status',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'source',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quelle',
        sortable: false,
        tooltip: 'First return value',
        minWidth: '130px',
        maxWidth: '130px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 130 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'status',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Status',
        sortable: false,
        tooltip: 'Date',
        minWidth: '190px',
        maxWidth: '190px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 190 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
    ],
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isFondsPateUser
      ? [
          {
            id: 'actions',
            label: 'Aktionen',
            tooltip: 'Actions',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                variant="short"
                menuWidth={286}
                isInMyPortfolio={row.is_watchlisted}
                isPresentInKompass={row.present_in_kompass}
                isCompassDesignRequestSent={row.is_compass_design_request_sent}
                disabledToRemoveFromPortfolio={!row.is_watchlisted}
                onChangePortfolioHandle={(e: React.BaseSyntheticEvent) => {
                  e.stopPropagation();
                  showConfirmRemoveFromPortfolioModal(
                    row.name,
                    row.id,
                    row.isin,
                  );
                }}
                // onChangeCompasStatusHandle={(e: React.BaseSyntheticEvent) => {
                //   e.stopPropagation();
                //   row.is_compass_design_request_sent
                //     ? showConfirmRemoveFromCompassModal(row.name, row.id, row.isin)
                //     : changeFundCompassStatus(row.id, row.name);
                // }}
                // isCompassDesignRequestSent={row.is_compass_design_request_sent}
                onChangeCompasStatusHandle={
                  isFondsPateUser
                    ? () => onChangeCompasStatusHandle(row)
                    : undefined
                }
                onFundUpdateHandle={() => {}}
                onRequestFundUpdateHandle={() => {}}
                onPlansecurCommentsHandle={() => {}}
                onFundPortraitHandle={() => {}}
                onRequestCompetitiveAnalysisHandle={() => {}}
                onOtherRequestHandle={() => {}}
              />
            ),
            width: '120px',
            subColumns: [],
          },
        ]
      : []),
  ];
  const getTableData = async () => {
    if (selectedPortfolio) {
      const response = await fetchPortfolios({
        id: selectedPortfolio?.value,
        page,
        page_size: itemsPerPage,
        ...(sortModel && sortModel.sort_by && sortModel.sort_order
          ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
          : {}),
        ...(searchValue ? { search: searchValue } : {}),
      });
      if (response.ok) {
        const tableData = response.parsedBody.results.funds;
        setResultsNumber(response.parsedBody.count);
        setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      }
    }
    setLoading(false);

    // const response = await fetchPlansecurFund({
    //   page,
    //   page_size: itemsPerPage,
    //   ...(sortModel && sortModel.sort_by && sortModel.sort_order
    //     ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
    //     : {}),
    //   ...(searchValue ? { search: searchValue } : {}),
    // });
    // if (response.ok) {
    //   const tableData = response.parsedBody.results.funds;
    //   setResultsNumber(response.parsedBody.count);
    //   setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams]);

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => true);
    // .some((row) => !row.is_favoured);
  };

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    isConfirmRemoveFromPortfolioOpen &&
      setIsConfirmRemoveFromPortfolioOpen(!isConfirmRemoveFromPortfolioOpen);
    isConfirmRemoveFromCompassOpen &&
      setIsConfirmRemoveFromCompassOpen(!isConfirmRemoveFromCompassOpen);
    isConfirmRequestFundPortraitOpen &&
      setIsConfirmRequestFundPortraitOpen(!isConfirmRequestFundPortraitOpen);
  };

  const handleSubmitConfirmModal = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      changeFundPortfolioStatus(currentFund.id, currentFund.name);
      return;
    }
    if (isConfirmRequestFundPortraitOpen) {
      sendRequestFundPortrait(currentFund.id, currentFund.name);
      return;
    }
  };

  const getConfirmModalTitle = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      return 'Aus dem Portfolio entfernen';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Aus dem Kompass entfernen';
    }
    return 'Fondsporträt';
  };

  const getConfirmModalSubtitle = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      return `Sind Sie sicher, dass Sie ${currentFund?.name} aus dem Portfolio entfernen möchten?`;
    }
    if (isConfirmRemoveFromCompassOpen) {
      return `Sind Sie sicher, dass Sie ${currentFund?.name} aus dem Kompass entfernen möchten?`;
    }
    return 'Leider ist der Fondsbericht derzeit nicht verfügbar. Möchten Sie ihn anfordern? Lassen Sie es uns wissen, und wir senden ihn Ihnen, sobald er verfügbar ist!';
  };

  const toogleConfirmWithCommentModal = () => {
    isConfirmAddToCompassOpen && setIsConfirmAddToCompassOpen(false);
    isConfirmRemoveFromCompassOpen && setIsConfirmRemoveFromCompassOpen(false);
  };

  return (
    <>
      <ConfirmModal
        isWarning={
          isConfirmRemoveFromPortfolioOpen || isConfirmRemoveFromCompassOpen
        }
        title={getConfirmModalTitle()}
        subtitle={getConfirmModalSubtitle()}
        isShown={
          isConfirmRemoveFromPortfolioOpen ||
          isConfirmRemoveFromCompassOpen ||
          isConfirmRequestFundPortraitOpen
        }
        toggle={toogleConfirmModal}
        onSubmit={handleSubmitConfirmModal}
        confirmButtonText={
          isConfirmRemoveFromPortfolioOpen || isConfirmRemoveFromCompassOpen
            ? 'Ja, Entfernen'
            : 'Fondsporträt anfordern'
        }
      />
      <ConfirmWithCommentModal
        isShown={isConfirmRemoveFromCompassOpen || isConfirmAddToCompassOpen}
        variant={
          isConfirmAddToCompassOpen
            ? 'send-request-to-add-to-kompass'
            : 'send-request-to-remove-from-kompass'
        }
        isWarning={isConfirmRemoveFromCompassOpen}
        title={`${
          isConfirmRemoveFromCompassOpen
            ? 'Aus Kompass-Entwurf entfernen'
            : 'In Kompass-Entwurf aufnehmen'
        }`}
        subtitle={
          isConfirmRemoveFromCompassOpen
            ? 'Sind Sie sicher, dass Sie die Anfrage zum Entfernen dieses Fonds aus dem Kompass senden möchten?'
            : 'Sind Sie sicher, dass Sie die Anfrage senden möchten, um diesen Fonds zum Kompass hinzuzufügen?'
        }
        confirmButtonText={
          isConfirmRemoveFromCompassOpen
            ? 'Ja, Entfernungsanfrage einreichen'
            : 'Ja, Anfrage zum Hinzufügen senden'
        }
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        toggle={toogleConfirmWithCommentModal}
        handleSubmitSend={() => getTableData()}
      />
      {/* <ConfirmWithCommentModal
        isShown={false}
        variant="send-request-to-remove-from-kompass"
        isWarning
        title={'Sonstige Anfragen/Anmerkungen'}
        subtitle="Sind Sie sicher, dass Sie die Anfrage zum Entfernen dieses Fonds aus dem Kompass senden möchten?"
        confirmButtonText="Ja, Entfernungsanfrage senden"
        fundName="1 Bona Futura - A"
        isin="LU1850437036"
        fundId="1"
        toggle={() => {}}
        handleSubmitSend={() => {}}
      /> */}
      <AcceptRequestWithCommentModal
        isShown={false}
        isWarning
        title="Aus Kompass-Entwurf entfernen"
        subtitle="Sind Sie sicher, dass Sie die Anfrage zur Löschung dieses Fonds aus dem Kompass-Entwurf akzeptieren möchten?"
        confirmButtonText="Ja, Aus Kompass-Entwurf entfernen"
        cancelButtonText="Nein, Ablehnen"
        fundName="1 Bona Futura - A"
        isin="LU1850437036"
        toggle={() => {}}
        onSubmit={() => {}}
        requestedBy="John Williams"
        requestDate="2022-11-08 14:32:21 +0100"
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec libero ultricies ultricies. Nullam nec purus nec libero ultricies ultricies."
      />
      <CommentsModal
        isShown={false}
        fundName="1 Bona Futura - A"
        isin="LU1850437036"
        comments={[
          {
            id: '1',
            name: 'John Williams',
            comment:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            date: '2024-11-08 14:32:21 +0100',
          },
          {
            id: '2',
            name: 'John Williams',
            comment:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            date: '2024-11-08 14:32:21 +0100',
          },
        ]}
        closeModal={() => {}}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        portfoliosOptions={portfoliosOptions}
        selectedPortfolio={selectedPortfolio}
        setSelectedPortfolio={setSelectedPortfolio}
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        {/* <SelectedFilters filters={[]} /> */}
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
              handleAddToPortfolio={() => {}}
              isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isFondsPateUser}
            loading={loading}
            tableData={{
              rows,
              columns: [columns[0], statusColumn, ...columns.slice(1)],
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
