import { FC, useRef } from 'react';
import { CommentFormProps, CommentsModalProps } from './CommentsModal.d';
import {
  StyledCloseButton,
  StyledFooter,
  StyledIsin,
  StyledModalContent,
  StyledModalHeader,
  StyledSlideOutModal,
} from './CommentsModal.styled';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Button } from 'components/common/Buttons/Button';
import { useClickOutside } from 'hooks/useClickOutside';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { useForm } from 'react-hook-form';
import { commentFormValidationSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { Textarea } from 'components/common/Textarea';
import { CommentItem } from './CommentItem';

export const CommentsModal: FC<CommentsModalProps> = ({
  isShown,
  fundName,
  isin,
  comments,
  closeModal,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { handleSubmit, register, reset } = useForm<CommentFormProps>({
    resolver: yupResolver(commentFormValidationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (feedbackFormData: CommentFormProps) => {
    // TODO: Implement onSubmit
  };

  useClickOutside(wrapperRef, () => {
    closeModal();
  });

  return (
    <StyledSlideOutModal isShown={isShown} ref={wrapperRef}>
      <StyledModalHeader>
        <StyledCloseButton onClick={closeModal}>
          <CloseIcon stroke={COLORS.typography.placeholder} />
        </StyledCloseButton>
      </StyledModalHeader>
      <StyledModalContent>
        <Box display="flex" flexDirection="column" gap={1} pb={5}>
          <Typography variant="h5">{fundName}</Typography>
          <StyledIsin variant="body">{isin}</StyledIsin>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textarea placeholder="Dein Kommentar..." {...register('comment')} />
          <Box display="flex" justifyContent="flex-end" pt={2}>
            <Button type="submit">Kommentar hinzufügen</Button>
          </Box>
        </form>
        {comments?.length !== 0 && (
          <Box display="flex" flexDirection="column" gap={2.25}>
            {comments?.map((comment, index) => (
              <CommentItem
                key={comment.id}
                isLast={index === comments.length - 1}
                {...comment}
              />
            ))}
          </Box>
        )}
      </StyledModalContent>
      <StyledFooter>
        <Button variant={ButtonVariant.OUTLINED} onClick={closeModal}>
          Abbrechen
        </Button>
        <Button onClick={() => {}}>Speichern</Button>
      </StyledFooter>
    </StyledSlideOutModal>
  );
};
