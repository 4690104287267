import { FC, useEffect, useState } from 'react';
import { DashboardCardItemProps, DashboardCardProps } from './DashboardCard.d';
import { Box } from 'components/common/Box';
import {
  StyledArrowIcon,
  StyledButton,
  StyledContainer,
  StyledName,
} from './DashboardCard.styled';
import {
  ButtonSize,
  ButtonVariant,
} from 'components/common/Buttons/Button/Button.enums';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { ModeSwitcher } from 'components/common/ModeSwitcher';
import { Typography } from 'components/common/Typography';
import { DashboardCardItem } from './DashboardCardItem';
import { fetchKompassFund } from 'services/Plansecur';

const ITEMS_TO_SHOW_COUNT = 15;

export const DashboardCard: FC<DashboardCardProps> = ({
  name,
  subtitle,
  link,
  isWarning,
}) => {
  const { customNavigate } = useNavigation();
  const [modeSwitcherValue, setModeSwitcherValue] = useState({
    value: 'sharpe_ratio_1_year',
    name: '1 Jahr',
    shortName: '1 J',
  });
  const [funds, setFunds] = useState<DashboardCardItemProps[]>([]);
  const modeSwitcherOptions = [
    { value: 'sharpe_ratio_1_year', name: '1 Jahr', shortName: '1 J' },
    { value: 'sharpe_ratio_3_years', name: '3 Jahre', shortName: '3 J' },
    { value: 'sharpe_ratio_5_years', name: '5 Jahre', shortName: '5 J' },
  ];

  const getCurrentValue = (value: DashboardCardItemProps) => {
    const key = modeSwitcherValue.value;
    return value.name ? value[key as keyof DashboardCardItemProps] : null;
  };

  const getFundsData = async () => {
    const response = await fetchKompassFund({
      page: 1,
      page_size: ITEMS_TO_SHOW_COUNT,
      sort_by: modeSwitcherValue.value,
      sort_order: isWarning ? 'desc' : 'asc',
    });
    if (response.ok) {
      const data = response.parsedBody.results.funds;
      const newFunds = data.map((item) => ({
        id: item.id,
        name: item.name,
        sharpe_ratio_1_year: item.sharpe_ratio_1_year || 0,
        sharpe_ratio_3_years: item.sharpe_ratio_3_years || 0,
        sharpe_ratio_5_years: item.sharpe_ratio_5_years || 0,
      }));
      setFunds(newFunds);
    }
  };

  useEffect(() => {
    getFundsData();
  }, [modeSwitcherValue]);

  return (
    <StyledContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <StyledName variant="h2">{name}</StyledName>
          <Box>
            <ModeSwitcher
              value={modeSwitcherValue}
              setValue={setModeSwitcherValue}
              options={modeSwitcherOptions}
              disabled={funds.length === 0}
            />
          </Box>
        </Box>
        <StyledButton
          variant={ButtonVariant.OUTLINED}
          size={ButtonSize.SMALL}
          rightIcon={<StyledArrowIcon />}
          onClick={() => customNavigate(link)}
          disabled={funds.length === 0}
        >
          Alle anzeigen
        </StyledButton>
      </Box>
      <Box pt={1} display="flex" gap={1} alignItems="center">
        <Typography variant="h6">
          {subtitle || ''} {modeSwitcherValue.name}
        </Typography>
      </Box>
      {funds.length === 0 ? (
        <Box pt={3}>
          <Typography variant="body" textAlign="center">
            Keine Daten gefunden
          </Typography>
        </Box>
      ) : (
        <Box pt={3} display="flex" flexDirection="column" gap={1}>
          {funds.map((item) => (
            <DashboardCardItem
              isWarning={isWarning}
              key={item.id}
              id={item.id}
              name={item.name}
              value={Number(getCurrentValue(item))}
            />
          ))}
          {funds.length < ITEMS_TO_SHOW_COUNT && (
            <DashboardCardItem
              isWarning={isWarning}
              key="random"
              id="random"
              name="..."
              value={null}
            />
          )}
        </Box>
      )}
    </StyledContainer>
  );
};
