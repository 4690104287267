import { Layout } from 'components/common/Layout';
import { FC, useEffect, useState } from 'react';
import { StyledContainer, StyledHeadline } from './CustomerOverview.styles';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';
import { Tabs } from 'components/Modules/CustomerOverview/Tabs/Tabs';
import { Dashboard } from 'components/Modules/CustomerOverview/Dashboard';
import { Box } from 'components/common/Box';
import { customerOverviewLogoIcons } from './config';
import { ReactComponent as PlansecurIcon } from 'assets/Icons/plansecur.svg';
import { ReactComponent as RefreshIcon } from 'assets/Icons/refresh.svg';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { FundSearch } from 'components/Modules/CustomerOverview/FundSearch';
import { Compass } from 'components/Modules/CustomerOverview/Compass';
import { CompassDesign } from 'components/Modules/CustomerOverview/CompassDesign';
import { Portfolios } from 'components/Modules/CustomerOverview/Portfolios';

export const CustomerOverview: FC = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const tabs = [
    { label: 'Dashboard', content: <Dashboard /> },
    { label: 'Fondssuche', content: <FundSearch /> },
    { label: 'Kompass', content: <Compass /> },
    { label: 'Entwurf Kompass', content: <CompassDesign /> },
    { label: 'Portfolios', content: <Portfolios /> },
  ];

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  return (
    <Layout headerVariant="secondary">
      <StyledContainer
        m={2.5}
        p={2.5}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <StyledHeadline variant="h1">Plansecur Produktkompass</StyledHeadline>
          <Box display="flex" gap={1.5} alignItems="center">
            <Box display="flex" gap={0.3}>
              {customerOverviewLogoIcons.map((letter) => letter)}
            </Box>
            <PlansecurIcon />
          </Box>
        </Box>
        <Tabs
          tabs={tabs}
          rightHeaderChildren={
            <Box display="flex" alignItems="center" gap={1}>
              <RefreshIcon />
              <Typography variant="body" color={COLORS.typography.placeholder}>
                Letzte Aktualisierung
              </Typography>
              <Typography variant="body">13.01.2025</Typography>
            </Box>
          }
        />
        {/* <StyledIconContainer key="chat" display="flex" justifyContent="center">
          <ChatIconButton onClick={toggleChat} />
        </StyledIconContainer> */}
      </StyledContainer>
    </Layout>
  );
};
