import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { commonColumns, createRow } from '../config';
import { fetchKompassFund } from 'services/Plansecur';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { useProfile } from 'context/Profile';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { raiseToast } from 'components/common/Toast/raiseToast';

export const Compass: FC = () => {
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const { search } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmSendRequestOfFundPortrait,
    setIsConfirmSendRequestOfFundPortrait,
  ] = useState(false);

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    {
      id: 'actions',
      label: 'Aktionen',
      tooltip: 'Actions',
      renderCell: ({ row }) => (
        <Actions
          onFundPortraitHandle={() =>
            setIsConfirmSendRequestOfFundPortrait(true)
          }
          justifyContent="center"
          mr={0}
        />
      ),
      width: '58px',
      subColumns: [],
    },
  ];
  const getTableData = async () => {
    const response = await fetchKompassFund({
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    });
    if (response.ok) {
      const tableData = response.parsedBody.results.funds;
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams]);

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => true);
    // .some((row) => !row.is_favoured);
  };

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    setIsConfirmSendRequestOfFundPortrait(!isConfirmSendRequestOfFundPortrait);
  };

  const sendFundPortraitRequest = async () => {
    if (isConfirmSendRequestOfFundPortrait) {
      console.log('send request');
    }
    isConfirmSendRequestOfFundPortrait &&
      raiseToast.send(
        'Die Anfrage für das Fondsporträt wurde erfolgreich gesendet.',
      );
    isConfirmSendRequestOfFundPortrait &&
      setIsConfirmSendRequestOfFundPortrait(false);
  };

  return (
    <>
      <ConfirmModal
        title="Fondsporträt"
        subtitle="Leider ist der Fondsbericht derzeit nicht verfügbar. Möchten Sie ihn anfordern? Lassen Sie es uns wissen, und wir senden ihn Ihnen, sobald er verfügbar ist!"
        isShown={isConfirmSendRequestOfFundPortrait}
        toggle={toogleConfirmModal}
        onSubmit={sendFundPortraitRequest}
        confirmButtonText="Fondsporträt anfordern"
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        {/* <SelectedFilters filters={[]} /> */}
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
              handleAddToPortfolio={() => {}}
              isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            />
          )}
          <PortfolioCollapseTable
            loading={loading}
            tableData={{ rows, columns }}
            selectedRows={selectedRows}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
