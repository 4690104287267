import styled, { css } from 'styled-components';
import { styled as MUIStyled } from '@mui/material';
import { FixedColumn, FixedHeadColumn } from '../PortfolioCollapseTable.styled';

export const StyledSelectCell = MUIStyled(FixedColumn)({
  maxWidth: '18px',
  width: '18px',
  minWidth: '18px',
  padding: '10px 12px 10px 16px !important',
});

export const StyledHeadSelectCell = MUIStyled(FixedHeadColumn)({
  maxWidth: '18px',
  width: '18px',
  minWidth: '18px',
  padding: '10px 12px 10px 16px !important',
});

export const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  width: 18px;
  height: 23px;
  border: none;
  background-color: unset;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 50%;
    pointer-events: none;
  }
`;
