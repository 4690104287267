import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { commonColumns, createRow } from '../config';
import { useProfile } from 'context/Profile';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchPlansecurFund,
  fetchUpdateFundPortfolioStatus,
} from 'services/Plansecur';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { ConfirmWithCommentModal } from '../Modals/ConfirmWithCommentModal';
import { PlansecurFundProps } from 'types/Plansecur.d';

const DAFAULT_PAGE_SIZE = 50;

export const FundSearch: FC = () => {
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const { search } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(
    query.get('page-size') || DAFAULT_PAGE_SIZE.toString(),
    10,
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmRemoveFromPortfolioOpen,
    setIsConfirmRemoveFromPortfolioOpen,
  ] = useState(false);
  const [isConfirmRemoveFromCompassOpen, setIsConfirmRemoveFromCompassOpen] =
    useState(false);
  const [isConfirmAddToCompassOpen, setIsConfirmAddToCompassOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
  });

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const showConfirmRemoveFromPortfolioModal = (
    name: string,
    id: string,
    isin: string,
  ) => {
    setIsConfirmRemoveFromPortfolioOpen(true);
    setCurrentFund({ id, name, isin });
  };

  const showConfirmWithCommentModal = (
    name: string,
    id: string,
    isin: string,
  ) => {
    setIsConfirmRemoveFromPortfolioOpen(true);
    setCurrentFund({ id, name, isin });
  };

  const changeFundPortfolioStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundPortfolioStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromPortfolioOpen &&
        setIsConfirmRemoveFromPortfolioOpen(false);
      isConfirmRemoveFromPortfolioOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Portfolio 1 entfernt"
              content={`"${name}" wurde aus Ihrer Portfolio 1 entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const changeFundCompassStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundPortfolioStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromPortfolioOpen &&
        setIsConfirmRemoveFromPortfolioOpen(false);
      isConfirmRemoveFromPortfolioOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const onChangePortfolioHandle = (row: RowProps) => {
    row.is_watchlisted
      ? showConfirmRemoveFromPortfolioModal(row.name, row.id, row.isin)
      : changeFundPortfolioStatus(row.id, row.name);
  };

  const onChangeCompasStatusHandle = ({
    id,
    name,
    isin,
    is_compass_design_request_sent,
  }: RowProps) => {
    is_compass_design_request_sent
      ? setIsConfirmRemoveFromCompassOpen(true)
      : setIsConfirmAddToCompassOpen(true);

    setCurrentFund({ id, name, isin });
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isFondsPateUser
      ? [
          {
            id: 'actions',
            label: 'Aktionen',
            tooltip: 'Aktionen',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                isInMyPortfolio={row.is_watchlisted}
                onChangePortfolioHandle={
                  isFondsPateUser
                    ? () => onChangePortfolioHandle(row)
                    : undefined
                }
                // isCompassDesignRequestSent={row.is_compass_design_request_sent}
                // onChangeCompasStatusHandle={
                //   isPlansecurAdmin ? () => onChangeCompasStatusHandle(row) : undefined
                // }
                justifyContent="center"
                mr={0}
              />
            ),
            width: '58px',
            subColumns: [],
          },
        ]
      : []),
  ];

  const getTableData = async () => {
    const response = await fetchPlansecurFund({
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    });
    if (response.ok) {
      const tableData = response.parsedBody.results.funds;
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams]);

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => true);
    // .some((row) => !row.is_favoured);
  };

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    setIsConfirmRemoveFromPortfolioOpen(!isConfirmRemoveFromPortfolioOpen);
  };

  const toogleConfirmWithCommentModal = () => {
    isConfirmAddToCompassOpen && setIsConfirmAddToCompassOpen(false);
    isConfirmRemoveFromCompassOpen && setIsConfirmRemoveFromCompassOpen(false);
  };

  const handleSubmitConfirmModal = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      changeFundPortfolioStatus(currentFund.id, currentFund.name);
      return;
    }
  };

  return (
    <>
      <ConfirmModal
        isWarning={isConfirmRemoveFromPortfolioOpen}
        title={'Aus dem Portfolio entfernen'}
        subtitle={`Sind Sie sicher, dass Sie ${currentFund?.name} aus dem Portfolio entfernen möchten?`}
        isShown={isConfirmRemoveFromPortfolioOpen}
        toggle={toogleConfirmModal}
        onSubmit={handleSubmitConfirmModal}
        confirmButtonText="Ja, Entfernen"
      />
      {/* <ConfirmWithCommentModal
        isShown={isConfirmRemoveFromCompassOpen || isConfirmAddToCompassOpen}
        variant={
          isConfirmAddToCompassOpen
            ? 'send-request-to-add-to-kompass'
            : 'send-request-to-remove-from-kompass'
        }
        isWarning={isConfirmRemoveFromCompassOpen}
        title={`${
          isConfirmRemoveFromCompassOpen
            ? 'Aus Kompass-Entwurf entfernen'
            : 'In Kompass-Entwurf aufnehmen'
        }`}
        subtitle={
          isConfirmRemoveFromCompassOpen
            ? 'Sind Sie sicher, dass Sie die Anfrage zum Entfernen dieses Fonds aus dem Kompass senden möchten?'
            : 'Sind Sie sicher, dass Sie die Anfrage senden möchten, um diesen Fonds zum Kompass hinzuzufügen?'
        }
        confirmButtonText={
          isConfirmRemoveFromCompassOpen
            ? 'Ja, Entfernungsanfrage einreichen'
            : 'Ja, Anfrage zum Hinzufügen senden'
        }
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        toggle={toogleConfirmWithCommentModal}
        handleSubmitSend={() => getTableData()}
      /> */}
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => getTableData()}
      >
        {/* <SelectedFilters filters={[]} /> */}
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
              handleAddToPortfolio={isFondsPateUser ? () => {} : undefined}
              // handleAddToCompassDesign={isPlansecurAdmin ? () => {} : undefined}
              isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isFondsPateUser}
            loading={loading}
            tableData={{ rows, columns }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination
          pagesNumber={getPagesNumber()}
          defaultPageSize={DAFAULT_PAGE_SIZE}
        />
      </ModuleLayout>
    </>
  );
};
