import {
  CHANGE_FUND_PORTFOLIO_STATUS,
  KOMPASS_DESIGN,
  KOMPASS_FUND,
  PLANSECUR_FUND,
  PORTFOLIOS,
  REQUEST_FUND_PORTRAIT,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  PlansecurFundProps,
  PlansecurFundsProps,
  PlansecurKompassDesignItemProps,
} from 'types/Plansecur.d';

export const fetchUpdateFundPortfolioStatus = (id: string) =>
  fetchAPI(CHANGE_FUND_PORTFOLIO_STATUS(id), {
    method: 'PATCH',
  });

interface AddRemoveFundToKompassRequest {
  fund: string;
  notes: string;
  type: 'Addition' | 'Removal';
}

export const fetchAddRemoveFundToCompassStatus = ({
  fund,
  notes,
  type,
}: AddRemoveFundToKompassRequest) =>
  fetchAPI(`${KOMPASS_DESIGN}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fund, notes, type }),
  });

export const fetchUpdateFundCompassStatus = (id: string) =>
  fetchAPI(CHANGE_FUND_PORTFOLIO_STATUS(id), {
    method: 'PATCH',
  });

export const sendRequestFundPortfolio = (id: string) =>
  fetchAPI(REQUEST_FUND_PORTRAIT(id), {
    method: 'PATCH',
  });

interface PlansecurFundRequest {
  page?: number;
  page_size?: number;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

interface PlansecurFundResponse extends Response {
  results: PlansecurFundsProps;
  count: number;
}

export const fetchPlansecurFund = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
}: PlansecurFundRequest) => {
  const fetchPSF = fetchAPI<PlansecurFundResponse>(
    `${PLANSECUR_FUND}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPSF;
};

export const fetchKompassFund = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
}: PlansecurFundRequest) => {
  const fetchPSF = fetchAPI<PlansecurFundResponse>(
    `${KOMPASS_FUND}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPSF;
};

interface KompassDesignResponse extends Response {
  results: { requests: PlansecurKompassDesignItemProps[] };
  count: number;
}

export const fetchKompassDesign = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
}: PlansecurFundRequest) => {
  const fetchPSF = fetchAPI<KompassDesignResponse>(
    `${KOMPASS_DESIGN}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPSF;
};

interface PortfoliosRequest {
  id: string;
  page?: number;
  page_size?: number;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

interface PortfoliosFundResponse extends Response {
  id: string;
  name: string;
  portfolio_funds: PlansecurFundProps[];
  watchlisted_funds: PlansecurFundProps[];
}

export const fetchPortfolios = ({
  id,
  page,
  page_size,
  sort_by,
  sort_order,
  search,
}: PortfoliosRequest) => {
  const fetchPortfolioFunds = fetchAPI<PlansecurFundResponse>(
    `${PORTFOLIOS(id)}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPortfolioFunds;
};

interface AcceptRequestToChangeCompassRequest {
  id: string;
  status: 'Pending' | 'Rejected' | 'Accepted';
}

export const fetchAcceptRequestToChangeCompass = ({
  id,
  status,
}: AcceptRequestToChangeCompassRequest) =>
  fetchAPI(`${KOMPASS_DESIGN}/${id}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ status }),
  });
