import { styled as MUIStuled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledMenuItemProps, StyledMenuProps } from './ExpandedActions.d';

export const StyledMenu = MUIStuled(Menu)<StyledMenuProps>(({ width }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    borderColor: COLORS.stroke.primary,
    minWidth: 185,
    maxWidth: width || 200,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
}));

export const StyledMenuItem = MUIStuled(MenuItem)<StyledMenuItemProps>(
  ({ isWarning, isWarningOnHover }) => ({
    padding: '12px 16px',
    letterSpacing: 0,
    whiteSpace: 'normal',
    gap: '10px',
    color: isWarning ? COLORS.error.background : COLORS.typography.description,
    fontSize: '13px',
    lineHeight: '17px',
    fontFamily: 'Inter',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      background: isWarningOnHover
        ? COLORS.error.hover
        : COLORS.background.neutral,
      color: isWarningOnHover
        ? COLORS.error.background
        : COLORS.info.selectedBorder,
      '& svg': {
        fill: isWarningOnHover
          ? COLORS.error.background
          : COLORS.info.selectedBorder,
      },
    },
  }),
);
