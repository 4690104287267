import {
  ColumnProps,
  RowProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { NameColumn } from '../NameColumn';
import { StarRatingsColumn } from '../StarRatingsColumn';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { CheckStatusIndicator } from 'components/common/Icons/CheckStatusIndicator';
import { PurchasePriceIndicator } from '../PurchasePriceIndicator';
import { Rank } from '../Rank';
import { Option } from 'components/common/Select/Select.d';
import { Box } from 'components/common/Box';
import { TableChip } from 'components/common/TableChip/TableChip';
import { StyledTableChipContainer } from './CustomerOverview.styled';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from '../Tables/FCSmartFundBenchmarkingTable/config';
import { Score } from '../Score';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { formatNumberWithComma, parseDate } from 'utils/common';
import { COLORS } from 'theme/colors';

const getDefaultFilterValue = (value: string, options: Option[]) => {
  const [result] = options.filter((option) => option.value === value);
  return result;
};

export const statusOptions: Option[] = [
  {
    value: 'In Kompass aufnehmen',
    name: 'In Kompass aufnehmen',
    chipVariant: TableChipVariant.GREEN,
  },
  {
    value: 'Aus Kompass streichen',
    name: 'Aus Kompass streichen',
    chipVariant: TableChipVariant.RED,
  },
];

export const createRow = (tableData: PlansecurFundProps): RowProps => {
  return {
    id: tableData.id,
    scope_rating: tableData.scope_rating,
    appropriation_of_earnings: tableData.appropriation_of_earnings ?? '-',
    volume_based_on: tableData.volume_based_on,
    fund_id: tableData.fund_id,
    isin: tableData.isin,
    wkn: tableData.wkn ?? '-',
    name: tableData.name,
    currency: tableData.currency ?? '-',
    company: tableData.company ?? '-',
    category: tableData.category ?? '-',
    regional_focus: tableData.regional_focus ?? '-',
    currency_focus: tableData.currency_focus ?? '-',
    launch_date: tableData.launch_date ? parseDate(tableData.launch_date) : '-',
    aa_percentage: tableData.aa_percentage ?? '-',
    issue_price: tableData.issue_price ?? '-',
    redemption_price: tableData.redemption_price ?? '-',
    price_currency: tableData.price_currency ?? '-',
    price_date: tableData.price_date ? parseDate(tableData.price_date) : '-',
    performance_current_year: tableData.performance_current_year ?? '-',
    performance_1_month: tableData.performance_1_month ?? '-',
    performance_3_months: tableData.performance_3_months ?? '-',
    performance_6_months: tableData.performance_6_months ?? '-',
    performance_1_year: tableData.performance_1_year ?? '-',
    performance_3_years: tableData.performance_3_years ?? '-',
    performance_5_years: tableData.performance_5_years ?? '-',
    performance_10_years: tableData.performance_10_years ?? '-',
    performance_since_edition: tableData.performance_since_edition ?? '-',
    performance_3_years_pa: tableData.performance_3_years_pa ?? '-',
    performance_5_years_pa: tableData.performance_5_years_pa ?? '-',
    performance_10_years_pa: tableData.performance_10_years_pa ?? '-',
    performance_since_edition_pa: tableData.performance_since_edition_pa ?? '-',
    sharpe_ratio_1_year: tableData.sharpe_ratio_1_year ?? '-',
    sharpe_ratio_3_years: tableData.sharpe_ratio_3_years ?? '-',
    sharpe_ratio_5_years: tableData.sharpe_ratio_5_years ?? '-',
    treynor_ratio: tableData.treynor_ratio ?? '-',
    jensen_alpha: tableData.jensen_alpha ?? '-',
    volatility_1_year: tableData.volatility_1_year ?? '-',
    volatility_3_years: tableData.volatility_3_years ?? '-',
    volatility_5_years: tableData.volatility_5_years ?? '-',
    volatility_10_years: tableData.volatility_10_years ?? '-',
    information_ratio: tableData.information_ratio ?? '-',
    fww_fundstars_rating: tableData.fww_fundstars_rating,
    morningstar_rating: tableData.morningstar_rating,
    index_fund: tableData.index_fund,
    vl_fund: tableData.vl_fund,
    ffb_online_order_cutoff_time: tableData.ffb_online_order_cutoff_time ?? '-',
    price_fixing_purchase_offset: tableData.price_fixing_purchase_offset,
    price_fixing_sale_offset: tableData.price_fixing_sale_offset,
    custody_fee: tableData.custody_fee,
    portfolio_eligible: tableData.portfolio_eligible,
    savings_plan_eligible: tableData.savings_plan_eligible,
    buy: tableData.buy,
    sell: tableData.sell,
    payment_plan: tableData.payment_plan,
    fund_of_funds: tableData.fund_of_funds,
    volume_in_million_eur: tableData.volume_in_million_eur
      ? formatNumberWithComma(tableData.volume_in_million_eur)
      : '-',
    suitability_assessment_fund_category:
      tableData.suitability_assessment_fund_category ?? '-',
    fng_label: tableData.fng_label ?? '-',
    sustainability_star_rating: tableData.sustainability_star_rating ?? '-',
    eet_template_version: tableData.eet_template_version ?? '-',
    esg_label_or_standard: tableData.esg_label_or_standard ?? '-',
    pornography_exclusion: tableData.pornography_exclusion,
    exclusion_threshold: tableData.exclusion_threshold ?? '-',
    sustainability_preference_sfdr:
      tableData.sustainability_preference_sfdr ?? '-',
    ungc_oecd_violations_considered: tableData.ungc_oecd_violations_considered,
    is_compass_design_request_sent: false,
    is_in_my_portfolio: false,
    present_in_kompass: tableData.present_in_kompass,
    is_watchlisted: tableData.is_watchlisted ?? null,
    user__name: tableData.user__name ?? '-',
    created_at: tableData.created_at ? tableData.created_at : '-',
    requested_by_comment: tableData.requested_by_comment ?? '-',
    requestType: tableData.requestType ?? null,
    requestId: tableData.request_id ?? '-',
    highlightColor: tableData.requestType
      ? tableData.requestType === 'Addition'
        ? COLORS.success.bulletPoint
        : COLORS.error.background
      : COLORS.background.secondary,
    sfb_related_fund__peer_group:
      tableData?.sfb_related_fund?.peer_group ?? '-',
    sfb_related_fund__rank__rank:
      tableData?.sfb_related_fund?.rank?.rank ?? null,
    quartil: tableData.quartil ?? '-',
    sfb_related_fund__volume: tableData.sfb_related_fund?.volume ?? '-',
    sfb_related_fund__track_record:
      tableData.sfb_related_fund?.track_record ?? '-',
    sfb_related_fund__total_active_management_score:
      tableData.sfb_related_fund?.total_active_management_score || null,
    sfb_related_fund__total_risk_score:
      tableData.sfb_related_fund?.total_risk_score || null,
    sfb_related_fund__esg_scores__credibility_score:
      tableData?.sfb_related_fund?.esg_scores &&
      tableData.sfb_related_fund.esg_scores?.credibility_score
        ? tableData.sfb_related_fund.esg_scores?.credibility_score?.toString()
        : null,
    nr_of_funds: tableData?.sfb_related_fund?.rank
      ? tableData?.sfb_related_fund.rank.nr_of_funds
      : null,
    esg_actions: '-',
    sfb_related_fund__return_score__total_score:
      tableData.sfb_related_fund?.return_score.total_score?.label || null,
    status: tableData.status ?? '-',
    source: tableData.source,
  };
};

export const commonColumns: ColumnProps[] = [
  {
    id: 'name',
    label: 'Name',
    tooltip: 'The name of the item',
    sortable: true,
    renderCell: ({ row, value }) => {
      return <NameColumn variant="small" name={value} type={row.isin} />;
    },
    subColumns: [],
  },
  {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        tooltip: 'Peergroup',
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'quartil',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quartil',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        sortable: true,
        tooltip: 'ESG Actions (Fonds)',
        minWidth: '112px',
        maxWidth: '112px',
      },
    ],
  },
  {
    id: 'stammdaten',
    label: 'Stammdaten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'wkn',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'WKN',
        sortable: true,
        tooltip: 'WKN',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondswährung',
        sortable: true,
        tooltip: 'Fondswährung',
        minWidth: '125px',
        maxWidth: '125px',
      },
      {
        id: 'company',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Gesellschaft',
        sortable: true,
        tooltip: 'Gesellschaft',
        minWidth: '160px',
        maxWidth: '160px',
      },
      {
        id: 'category',
        headAlign: 'left',
        cellAlign: 'left',
        label: 'Fondskategorie / Asset-Schwerpunkt',
        sortable: true,
        tooltip: 'GesellscFondskategorie / Asset-Schwerpunkthaft',
        minWidth: '169px',
        maxWidth: '169px',
      },
      {
        id: 'regional_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Regionenschwerpunkt',
        sortable: true,
        tooltip: 'Regionenschwerpunkt',
        minWidth: '177px',
        maxWidth: '177px',
      },
      {
        id: 'currency_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Währungsschwerpunkt',
        sortable: true,
        tooltip: 'Währungsschwerpunkt',
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'launch_date',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Auflegung',
        sortable: true,
        tooltip: 'Auflegung',
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'preis',
    label: 'Preis',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'aa_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'AA in %',
        sortable: true,
        tooltip: 'AA in %',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'issue_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausgabepreis',
        sortable: true,
        tooltip: 'Ausgabepreis',
        minWidth: '118px',
        maxWidth: '118px',
      },
      {
        id: 'redemption_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rücknahmepreis',
        sortable: true,
        tooltip: 'Rücknahmepreis',
        minWidth: '138px',
        maxWidth: '138px',
      },
      {
        id: 'price_currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preiswährung',
        sortable: true,
        tooltip: 'Preiswährung',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'price_date',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisdatum',
        sortable: true,
        tooltip: 'Preisdatum',
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'rendite',
    label: 'Rendite',
    visibleSubColumnsNumber: 4,
    subColumns: [
      {
        id: 'performance_current_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Laufendes Jahr',
        sortable: true,
        tooltip: 'Laufendes Jahr',
        minWidth: '130px',
        maxWidth: '130px',
      },
      {
        id: 'performance_1_month',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Monat',
        sortable: true,
        tooltip: '1 Monat',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Monate',
        sortable: true,
        tooltip: '3 Monate',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '6 Monate',
        sortable: true,
        tooltip: '6 Monate',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        tooltip: '1 Jahr',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        tooltip: '3 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        tooltip: '5 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        tooltip: '10 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_since_edition',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage',
        sortable: true,
        tooltip: 'Seit Auflage',
        minWidth: '108px',
        maxWidth: '108px',
      },
      {
        id: 'performance_3_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre p.a.',
        sortable: true,
        tooltip: '3 Jahre p.a.',
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_5_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre p.a.',
        sortable: true,
        tooltip: '5 Jahre p.a.',
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_10_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre p.a.',
        sortable: true,
        tooltip: '10 Jahre p.a.',
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'performance_since_edition_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage p.a.',
        sortable: true,
        tooltip: 'Seit Auflage p.a.',
        minWidth: '137px',
        maxWidth: '137px',
      },
    ],
  },
  {
    id: 'aktives_anagement',
    label: 'Aktives Management',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sharpe_ratio_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 1 Jahr',
        sortable: true,
        tooltip: 'Sharpe Ratio 1 Jahr',
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'sharpe_ratio_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 3 Jahre',
        sortable: true,
        tooltip: 'Sharpe Ratio 3 Jahre',
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'sharpe_ratio_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 5 Jahre',
        sortable: true,
        tooltip: 'Sharpe Ratio 5 Jahre',
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'treynor_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Treynor Ratio',
        sortable: true,
        tooltip: 'Treynor Ratio',
        minWidth: '116px',
        maxWidth: '116px',
      },
      {
        id: 'jensen_alpha',
        headAlign: 'center',
        cellAlign: 'center',
        label: "Jensen's Alpha",
        sortable: true,
        tooltip: "Jensen's Alpha",
        minWidth: '130px',
        maxWidth: '130px',
      },
    ],
  },
  {
    id: 'volatility',
    label: 'Volatilität',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'volatility_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        tooltip: '1 Jahr',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        tooltip: '3 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        tooltip: '5 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        tooltip: '10 Jahre',
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   visibleSubColumnsNumber: 1,
  //   subColumns: [
  //     {
  //       id: 'status_1',
  //       headAlign: 'center',
  //       cellAlign: 'center',
  //       label: 'Anfragen',
  //       sortable: true,
  //       tooltip: 'Anfragen',
  //       minWidth: '216px',
  //       maxWidth: '216px',
  //       renderCell: ({ value }) => {
  //         const currentValue = getDefaultFilterValue(value, statusOptions);
  //         return !value || value === '-' ? (
  //           <span>-</span>
  //         ) : (
  //           <Box
  //             display="flex"
  //             justifyContent="center"
  //             style={{ width: '100%' }}
  //           >
  //             <TableChip
  //               label={value.toLowerCase() as ChipVariant}
  //               tableChipVaraint={currentValue.chipVariant}
  //             />
  //           </Box>
  //         );
  //       },
  //     },
  //   ],
  // },
  {
    id: 'information_ratio_data',
    label: '',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'information_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Information Ratio',
        sortable: true,
        tooltip: 'Information Ratio',
        minWidth: '147px',
        maxWidth: '147px',
      },
    ],
  },
  {
    id: 'externe_ratings',
    label: 'Externe Ratings',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'fww_fundstars_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FWW FundStars(R)',
        sortable: true,
        tooltip: 'FWW FundStars(R)',
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'morningstar_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Morningstar Rating(TM)',
        sortable: true,
        tooltip: 'Morningstar Rating(TM)',
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'scope_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Scope Mutual Fund Rating',
        sortable: true,
        tooltip: 'Scope Mutual Fund Rating',
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
    ],
  },
  {
    id: 'abwicklung',
    label: 'Fondsstruktur & Abwicklung',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'appropriation_of_earnings',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ertragsverwendung',
        sortable: true,
        tooltip: 'Ertragsverwendung',
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'index_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Indexfonds',
        sortable: true,
        tooltip: 'Indexfonds',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'vl_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'VL-Fonds',
        sortable: true,
        tooltip: 'VL-Fonds',
        minWidth: '90px',
        maxWidth: '90px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'ffb_online_order_cutoff_time',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FFB-Online\n -Orderschlusszeit',
        sortable: true,
        tooltip: 'FFB-Online -Orderschlusszeit',
        minWidth: '150px',
        maxWidth: '150px',
      },
      {
        id: 'price_fixing_purchase_offset',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisfestste -llung Kauf',
        sortable: true,
        tooltip: 'Preisfestste -llung Kauf',
        minWidth: '106px',
        maxWidth: '106px',
        renderCell: ({ value }) => <PurchasePriceIndicator value={value} />,
      },
      {
        id: 'custody_fee',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verwahrentgelt',
        sortable: true,
        tooltip: 'Verwahrentgelt',
        minWidth: '129px',
        maxWidth: '129px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'portfolio_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Portfoliofähig',
        sortable: true,
        tooltip: 'Portfoliofähig',
        minWidth: '117px',
        maxWidth: '117px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'savings_plan_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sparplanfähig',
        sortable: true,
        tooltip: 'Sparplanfähig',
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'buy',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kauf',
        sortable: true,
        tooltip: 'Kauf',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'sell',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verkauf',
        sortable: true,
        tooltip: 'Verkauf',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'payment_plan',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Auszahlplan',
        sortable: true,
        tooltip: 'Auszahlplan',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'fund_of_funds',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Dachfonds',
        sortable: true,
        tooltip: 'Dachfonds',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'volume_in_million_eur',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen umgerechnet\n in Mio. EUR',
        sortable: true,
        tooltip: 'Volumen umgerechnet in Mio. EUR',
        minWidth: '178px',
        maxWidth: '178px',
      },
      {
        id: 'volume_based_on',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen\n bezogen auf',
        sortable: true,
        tooltip: 'Volumen bezogen auf',
        minWidth: '141px',
        maxWidth: '141px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 155 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'suitability_assessment_fund_category',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Angemessenheitsbeurteilung - Fondskategorie',
        sortable: true,
        tooltip: 'Angemessenheitsbeurteilung - Fondskategorie',
        minWidth: '225px',
        maxWidth: '225px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 140 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
    ],
  },
  {
    id: 'nachhaltigkeit',
    label: 'Nachhaltigkeit',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'fng_label',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Siegel',
        sortable: true,
        tooltip: 'FNG-Siegel',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_star_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sterne',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'eet_template_version',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'EET Template Version',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'esg_label_or_standard',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Label or Standard',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '110px',
        maxWidth: '110px',
      },
      {
        id: 'pornography_exclusion',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion PorNeingraphie',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'exclusion_threshold',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion Threshold',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_preference_sfdr',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'NH-Präferenz gem. SFDR',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'ungc_oecd_violations_considered',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'UNGC Principles Or OECD Guidelines Violations Considered',
        sortable: true,
        tooltip: 'Sterne',
        minWidth: '250px',
        maxWidth: '250px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
];
