import { Box } from 'components/common/Box';
import styled from 'styled-components';
import {
  SettlementPeriodsProps,
  StyledContainerProps,
} from './PurchasePriceIndicator.d';
import { settlementPeriods } from './config';

const getColorsByValue = (
  value: number,
): SettlementPeriodsProps | undefined => {
  return settlementPeriods.find(({ period }) => {
    const [min, max] = period;
    return value >= min && value <= max;
  });
};

export const StyledContainer = styled(Box)<StyledContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ value }) => getColorsByValue(value)?.backgroundColor};
  color: ${({ value }) => getColorsByValue(value)?.textColor};
  border: 1px solid ${({ value }) => getColorsByValue(value)?.borderColor};
  width: 31px;
  height: 31px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
